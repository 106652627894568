
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableLocationModel } from '../model/table.model';
import { TableLocationService } from '../table-location.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-table-location-edit',
  templateUrl: './table-location-edit.component.html',
  styleUrl: './table-location-edit.component.scss'
})
export class TableLocationEditComponent {
  title: string = "New Table";
    editForm: FormGroup;
    tableLocationInfo: TableLocationModel = {};
    isFormDirty: boolean = false;
    ratingList : TableLocationModel[]= [];
    
    constructor(private router: Router,
      private builder: FormBuilder,
      private route: ActivatedRoute,
      private tableService: TableLocationService,
      private snackBarService: SnackBarService,
      public dialog: MatDialog,) {
  
      let tableId = this.route.snapshot.paramMap.get('id');
      this.editForm = this.builder.group({
        code: this.builder.control(this.tableLocationInfo.code, [Validators.required, Validators.maxLength(10)]),
        name: this.builder.control(this.tableLocationInfo.name, [Validators.required, Validators.maxLength(50)]),
        description: this.builder.control(this.tableLocationInfo.description)
        
      });
      
      this.editForm.valueChanges.subscribe(() => {
        this.isFormDirty = true;
      });
  
      if (tableId !== null && parseInt(tableId) !== 0) {
        this.setTablelocationInfo(tableId);
        
      }
      
    }
    ngOnInit(): void {
    //  this.loadRating(); 
       
     }
  
    /**
     * Dirtyness check
     * @returns 
     */
    isDirty():boolean{
      return this.isFormDirty;
    }
  
    /**
     * checks the form is edited
     */
    navBack(): void {
     
        this.router.navigate(['/table-location']);
      
    }
  
    /**
     * If tas is is null set an empty area info
     * if area id is not null call api to rtrive the info
     * @param tableId 
     */
    setTablelocationInfo(tableId: any): void {
  
      let response = this.tableService.getTableById(tableId).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            this.tableLocationInfo = response.data.table;
            this.editForm.patchValue(this.tableLocationInfo);
            this.title = this.getTitle();
            this.isFormDirty = false;
            
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to get data from server. Connection to the server failed.',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });
    }
  
    /**
     * 
     * @returns  the title for the dialog
     * sine the same dialog is used for edit and new, dynamically decide the title
     */
    getTitle(): string {
      let title = (this.tableLocationInfo.id == 0) ? "New Table" : "Editing : " + this.tableLocationInfo.name;
      return title;
    }
  
    /**
       * On save
       * if the details are valid call the api to update the changes.
       */
    save(): void {
  
      if (this.editForm.valid) {
        this.editForm.markAllAsTouched();
        Object.assign(this.tableLocationInfo, this.editForm.value);
        this.tableService.update(this.tableLocationInfo).subscribe({
          next: (response) => {
            if (response.status == 'SUCCESS') {
              // handle success
              this.isFormDirty = false;
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Saved',
                message: 'The record for ' + this.tableLocationInfo.name + " has been updated succesfully."
              }
              this.snackBarService.openSnackBar(snckBarData);
            } else {
              let snackBarData: SnackBarData = {
                message: response.message,
                title: 'Failed!!!',
                type: 'error'
              }
              this.snackBarService.openSnackBar(snackBarData);
              this.router.navigate(['/table']);
  
            }
          },
          error: (error) => {
            // handle error
            let snackBarData: SnackBarData = {
              message: 'Failed to update. Connection to the server failed',
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        });
  
      }else{
        this.editForm.markAllAsTouched();
      }
    }

}
