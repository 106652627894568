<h1 mat-dialog-title class="edit-form-title fixed-title">{{data.title}}</h1>
    <mat-dialog-content class="edit-form-content">
      <!-- <mat-form-field class="fixed-filter">
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
      </mat-form-field> -->
      <!-- <mat-checkbox [(ngModel)]="showSelected" (change)="showSelectedItems()">Show selected items only</mat-checkbox> -->

      <div class="scrollable-content">
        <table mat-table [dataSource]="dataSource">

          <!-- No Column -->
          <ng-container matColumnDef="no">
            <th mat-header-cell *matHeaderCellDef style="width: 100px;"> No    </th>
            <td mat-cell *matCellDef="let saleItem; let i = index"> {{ i + 1 }} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="code" style="width: 200px;">
            <th mat-header-cell *matHeaderCellDef> Code </th>
            <td mat-cell *matCellDef="let saleItem"> {{saleItem.code}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let saleItem"> {{saleItem.name}} </td>
        </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="footer">
      <button mat-raised-button color="primary" (click)=" actionClose()">Close</button>
    </mat-dialog-actions>







