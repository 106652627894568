

import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersModel } from '../model/orders.model';
import { OrdersService } from '../orders.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { DepartmentService } from 'src/app/pages/system/department/department.service';
import { DepartmentModel } from 'src/app/pages/system/shopinfo/model/shopinfo.model';
import { EmployeeCategoryModel } from 'src/app/pages/employees/employee-category/employee-category/model/employee-category.model';
import { EmployeeCategoryService } from 'src/app/pages/employees/employee-category/employee-category/employee-category.service';

@Component({
  selector: 'app-orders-edit',
  templateUrl: './orders-edit.component.html',
  styleUrl: './orders-edit.component.scss'
})
export class OrdersEditComponent {

  title: string = "New Employee";
 
    customerInfo: OrdersModel = {};
    // itemInfo: OrdersModel = {};
    itemInfo: OrdersModel[] = [];
    paymentInfo: OrdersModel[] = [];
    isFormDirty: boolean = false;
    ratingList : OrdersModel[]= [];
    departmentList: DepartmentModel[] =[];
    employeeCatList: EmployeeCategoryModel[]=[];
  totalAmount: any;
  totalDiscount: any;
  netAmount: any;
    
    
    constructor(private router: Router,
      private builder: FormBuilder,
      private route: ActivatedRoute,
      private EmployeeService: OrdersService,
      private snackBarService: SnackBarService,
      private DepartmentService: DepartmentService,
      private EmployeeCategoryService: EmployeeCategoryService,
      public dialog: MatDialog,) {
        this.departmentList = [];
        this.employeeCatList = [];
      let orderId = this.route.snapshot.paramMap.get('id');
      console.log(orderId);
      
  
      if (orderId !== null && parseInt(orderId) !== 0) {
        this.setCustomerInfo(orderId);
        this.setItemInfo(orderId);
        this.setPaymentInfo(orderId)
        
      }
      // this.setEmployeeInfo(employeeId);
      
    }
    ngOnInit(): void {
    //  this.loadRating(); 
    
    
     }
  
    /**
     * Dirtyness check
     * @returns 
     */
    isDirty():boolean{
      return this.isFormDirty;
    }
  
    /**
     * checks the form is edited
     */
    navBack(): void {
     
        this.router.navigate(['/orders']);
      
    }
  
    /**
     * If tas is is null set an empty area info
     * if area id is not null call api to rtrive the info
     * @param orderId 
     */
    setCustomerInfo(orderId: any): void {
  
      let response = this.EmployeeService.getOrderById(orderId).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            this.customerInfo = response.data.orders;
            console.log(this.customerInfo)
            
            // this.title = this.getTitle();
            this.isFormDirty = false;
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to get data from server. Connection to the server failed.',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });
    }

    setItemInfo(orderId: any): void {
      this.totalAmount = 0;
      this.totalDiscount = 0;
      this.netAmount=0;
      let response = this.EmployeeService.getItemById(orderId).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            this.itemInfo = response.data.items;
            console.log(this.itemInfo)
            for (const item of this.itemInfo) {
              this.totalAmount += item.item_total || 0; // Default to 0 if item_total is null/undefined
              this.totalDiscount +=item.discount_amount || 0;
              this.netAmount = this.totalAmount-this.totalDiscount || 0;
            }
              console.log("amount",this.totalAmount)
           
           
            // this.title = this.getTitle();
            this.isFormDirty = false;
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to get data from server. Connection to the server failed.',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });
    }

    setPaymentInfo(orderId: any): void {
  
      let response = this.EmployeeService.getPaymentById(orderId).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            this.paymentInfo = response.data.payments;
            console.log(this.paymentInfo)
          
            // this.title = this.getTitle();
            this.isFormDirty = false;
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to get data from server. Connection to the server failed.',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });
    }

  
    /**
     * 
     * @returns  the title for the dialog
     * sine the same dialog is used for edit and new, dynamically decide the title
     */
    // getTitle(): string {
    //   let title = (this.employeeInfo.id == 0) ? "New Employee" : "Editing : " + this.employeeInfo.f_name;
    //   return title;
    // }
  
    /**
       * On save
       * if the details are valid call the api to update the changes.
       */
   
    
    getOrderRemarks(order: any): string {
      if (order?.remarks?.trim()) {
        const rex = /\r\n|\n|\r/gm;
        return  order.remarks.replace(rex, "<br>");
      }
      return ""; // Return an empty string for invalid remarks
    }
}
