
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { employeeModel } from '../model/employee.model';
import { EmployeeService } from '../employee.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { DepartmentService } from 'src/app/pages/system/department/department.service';
import { DepartmentModel } from 'src/app/pages/system/shopinfo/model/shopinfo.model';
import { EmployeeCategoryModel} from '../../employee-category/employee-category/model/employee-category.model';
import { EmployeeCategoryService } from '../../employee-category/employee-category/employee-category.service';
@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrl: './employee-edit.component.scss'
})
export class EmployeeEditComponent {
  title: string = "New Employee";
  editForm: FormGroup;
  employeeInfo: employeeModel = {};
  isFormDirty: boolean = false;
  ratingList : employeeModel[]= [];
  departmentList: DepartmentModel[] =[];
  employeeCatList: EmployeeCategoryModel[]=[];
  
  
  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private EmployeeService: EmployeeService,
    private snackBarService: SnackBarService,
    private DepartmentService: DepartmentService,
    private EmployeeCategoryService: EmployeeCategoryService,
    public dialog: MatDialog,) {
      this.departmentList = [];
      this.employeeCatList = [];
    let employeeId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.employeeInfo.code, [Validators.required, Validators.maxLength(10)]),
      f_name: this.builder.control(this.employeeInfo.f_name, [Validators.required, Validators.maxLength(50)]),
      m_name: this.builder.control(this.employeeInfo.m_name),
      l_name: this.builder.control(this.employeeInfo.m_name),
      status: this.builder.control(this.employeeInfo.status,[Validators.required]),
      dob: this.builder.control(this.employeeInfo.dob,[Validators.required]),
      doj: this.builder.control(this.employeeInfo.doj,[Validators.required]),
      sex: this.builder.control(this.employeeInfo.sex),
      description: this.builder.control(this.employeeInfo.description),
      cost_per_hour: this.builder.control(this.employeeInfo.cost_per_hour,[Validators.required]),
      over_time_pay_rate: this.builder.control(this.employeeInfo.over_time_pay_rate),
      card_no: this.builder.control(this.employeeInfo.card_no,[Validators.required]),
      work_permit: this.builder.control(this.employeeInfo.work_permit),
      department_id: this.builder.control(this.employeeInfo.department_id,[Validators.required]),
      employee_category_id: this.builder.control(this.employeeInfo.employee_category_id,),
      wage_type: this.builder.control(this.employeeInfo.wage_type,[Validators.required]),
      address: this.builder.control(this.employeeInfo.address,[Validators.required]),
      
    });
    
    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (employeeId !== null && parseInt(employeeId) !== 0) {
      this.setEmployeeInfo(employeeId);
    }
  }
  ngOnInit(): void {
  //  this.loadRating(); 
  this.loadDepartmentData();
  this.loadData();
   }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty():boolean{
    return this.isFormDirty;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {
   
      this.router.navigate(['/employee']);
    
  }

  /**
   * If tas is is null set an empty area info
   * if area id is not null call api to rtrive the info
   * @param employeeId 
   */
  setEmployeeInfo(employeeId: any): void {

    let response = this.EmployeeService.getemployeeById(employeeId).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.employeeInfo = response.data.employees;
          this.editForm.patchValue(this.employeeInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.employeeInfo.id == 0) ? "New Employee" : "Editing : " + this.employeeInfo.f_name;
    return title;
  }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {

    if (this.editForm.valid) {
      this.editForm.markAllAsTouched();
      Object.assign(this.employeeInfo, this.editForm.value);
      this.EmployeeService.update(this.employeeInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.employeeInfo.f_name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
            this.router.navigate(['/']);

          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }else{
      this.editForm.markAllAsTouched();
    }
  }

  loadDepartmentData(): void {
    let requestData = {
      "search": {
          "filters": {
              "scope": {
                  "limit": 10,
                  "offset": 0
              }
          },
          "sort": [
              {
                  "column": "name",
                  "order": "asc"
              }
          ]
      }
  };
    let response = this.DepartmentService.getDepartmentList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.departmentList=response.data.department;
        console.log(response.data.department)
  
       
       
      } 
    });
  
  }
  loadData(): void {
    let requestData = {
      "search": {
          "filters": {
              "scope": {
                  "limit": 10,
                  "offset": 0
              }
          },
          "sort": [
              {
                  "column": "name",
                  "order": "asc"
              }
          ]
      }
  };
    let response = this.EmployeeCategoryService.getEmployeeList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        //this.categoryList = response.data.categories;
        this.employeeCatList=response.data.employees ;
       
      
      } 
    });
  
  }
  
}
