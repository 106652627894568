<div
  class="table"
  [style.height.px]="tableImage.height"
  [style.width.px]="tableImage.width"
  (mousedown)="onMouseDown($event)"
  (touchstart)="onMouseDown($event)"
  [ngClass]="{ 'selected-table': selected }"
>
  <span class="title-span"> {{ table.code }}</span>
  @if(tableImage!==undefined && tableImage.image!==null &&
  tableImage.image!==''){
  <img
    src="{{ 'data:image/png;base64,' + tableImage.image }}"
    [style.height.px]="tableImage.height"
    [style.width.px]="tableImage.width"
    alt=""
  />

  } @else{
  <div
    [style.height.px]="tableImage.height"
    [style.width.px]="tableImage.width"
    style="background-color: lightgray; border: solid darkgrey"
  ></div>
  }
</div>
