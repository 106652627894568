<div class="images-container">
  <div *ngFor="let imageData of imageListData" class="image-container">
    <div *ngIf="imageData.image" class="image" (click)="selectImage(imageData)">
      <img
        [src]="'data:image/png;base64,' + imageData.image"
        alt="Image"
        class="image-preview"
      />
    </div>
    <div *ngIf="!imageData.image" class="no-image-container">
      <span class="no-image-text">No Image</span>
    </div>
  </div>
</div>
