import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SaleItemModel } from '../model/sale-item.model';
import { SaleItemService } from '../sale-item.service';
import { SnackBarData } from '../../../../common/components/snackbar/model/snackbar.model';
import { SnackBarService } from '../../../../common/components/snackbar/snackbar.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../../common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from '../../../../common/guards/form-deactivate.guard';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ItemCategoryService } from '../../item-category/item-category.service';
import { UomService } from '../../uom/uom.service';
import { KitchenService } from 'src/app/pages/system/kitchen/kitchen.service';
import { ChoiceListComponent } from '../components/choice-list/choice-list.component';
import { ChoiceService } from '../../choice/choice.service';

@Component({
  selector: 'app-sale-item-edit',
  templateUrl: './sale-item-edit.component.html',
  styleUrl: './sale-item-edit.component.scss'
})
export class SaleItemEditComponent implements DirtyCheck, OnInit {
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  myControl = new FormControl('');
  title: string = "New Sale Item";
  editForm: FormGroup;
  saleItemInfo: SaleItemModel = {};
  isFormDirty: boolean = false;
  isMultiColorChecked: boolean = false;
  isSecondColorEditable!: boolean;
  stock_item_id: any[] = [];
  kitchen_id: any;
  item_classes_id: any;
  tax_id: any;
  selectedValue!: string;
  selectedCode!: string;
  selectedStock: any;
  filteredOptions: any[] = [];
  selectedId: any;
  isLoading = true;
  itemCategoryList: any;
  groupItemList: any;
  taxList: any;
  uomList: any;
  kitchenList: any;
  saleItemData: any;

  displayedColumns = ['id', 'userId', 'title'];
  choiceList: any;
  selectedChoiceList: any;
  constructor(
    private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private saleItemService: SaleItemService,
    private snackBarService: SnackBarService,
    private itemService: ItemCategoryService,
    private uomService: UomService,
    private kitchenService: KitchenService,
    private choiceService: ChoiceService,
    public dialog: MatDialog,
  ) {
    let saleItemId = this.route.snapshot.paramMap.get('id');

    this.editForm = this.builder.group({
      code: [this.saleItemInfo.code, [Validators.required, Validators.maxLength(10)]],
      name: [this.saleItemInfo.name, [Validators.required, Validators.maxLength(50)]],
      alternative_name: [this.saleItemInfo.alternative_name],
      alternative_name_to_print: [this.saleItemInfo.alternative_name_to_print],
      name_to_print: [this.saleItemInfo.name_to_print],
      barcode: [this.saleItemInfo.barcode],
      group_item_id: [this.saleItemInfo.group_item_id, [Validators.required]],
      sub_class_id: [this.saleItemInfo.sub_class_id, [Validators.required]],
      display_order: [this.saleItemInfo.display_order],
      fg_color: [this.saleItemInfo.fg_color],
      bg_color: [this.saleItemInfo.bg_color],
      fixed_price: [this.saleItemInfo.fixed_price, [Validators.required, Validators.maxLength(10)]],
      tax_id: [this.saleItemInfo.tax_id, [Validators.required]],
      uom_id: [this.saleItemInfo.uom_id, [Validators.required]],
      kitchen_id: [this.saleItemInfo.kitchen_id],
      is_hot_item_1: [this.saleItemInfo.is_hot_item_1],
      is_hot_item_2: [this.saleItemInfo.is_hot_item_2],
      is_hot_item_3: [this.saleItemInfo.is_hot_item_3],
      hot_item_1_display_order: [this.saleItemInfo.hot_item_1_display_order],
      hot_item_2_display_order: [this.saleItemInfo.hot_item_2_display_order],
      hot_item_3_display_order: [this.saleItemInfo.hot_item_3_display_order],
      is_manufactured: [this.saleItemInfo.is_manufactured],
      is_require_weighing: [this.saleItemInfo.is_require_weighing],
      is_valid: [this.saleItemInfo.is_valid],
      attrib1_name: [this.saleItemInfo.attrib1_name],
      attrib1_options: [this.saleItemInfo.attrib1_options],
      attrib1_def_option: [this.saleItemInfo.attrib1_def_option],
      attrib1_allow_multiselect: [this.saleItemInfo.attrib1_allow_multiselect],
      attrib2_name: [this.saleItemInfo.attrib2_name],
      attrib2_options: [this.saleItemInfo.attrib2_options],
      attrib2_def_option: [this.saleItemInfo.attrib2_def_option],
      attrib2_allow_multiselect: [this.saleItemInfo.attrib2_allow_multiselect],
      attrib3_name: [this.saleItemInfo.attrib3_name],
      attrib3_options: [this.saleItemInfo.attrib3_options],
      attrib3_def_option: [this.saleItemInfo.attrib3_def_option],
      attrib3_allow_multiselect: [this.saleItemInfo.attrib3_allow_multiselect],
      attrib4_name: [this.saleItemInfo.attrib4_name],
      attrib4_options: [this.saleItemInfo.attrib4_options],
      attrib4_def_option: [this.saleItemInfo.attrib4_def_option],
      attrib4_allow_multiselect: [this.saleItemInfo.attrib4_allow_multiselect],
      attrib5_name: [this.saleItemInfo.attrib5_name],
      attrib5_options: [this.saleItemInfo.attrib5_options],
      attrib5_def_option: [this.saleItemInfo.attrib5_def_option],
      attrib5_allow_multiselect: [this.saleItemInfo.attrib5_allow_multiselect],
      attrib6_name: [this.saleItemInfo.attrib6_name],
      attrib6_options: [this.saleItemInfo.attrib6_options],
      attrib6_def_option: [this.saleItemInfo.attrib6_def_option],
      attrib6_allow_multiselect: [this.saleItemInfo.attrib6_allow_multiselect],
      attrib7_name: [this.saleItemInfo.attrib7_name],
      attrib7_options: [this.saleItemInfo.attrib7_options],
      attrib7_def_option: [this.saleItemInfo.attrib7_def_option],
      attrib7_allow_multiselect: [this.saleItemInfo.attrib7_allow_multiselect],
      attrib8_name: [this.saleItemInfo.attrib8_name],
      attrib8_options: [this.saleItemInfo.attrib8_options],
      attrib8_def_option: [this.saleItemInfo.attrib8_def_option],
      attrib8_allow_multiselect: [this.saleItemInfo.attrib8_allow_multiselect],
      attrib9_name: [this.saleItemInfo.attrib9_name],
      attrib9_options: [this.saleItemInfo.attrib9_options],
      attrib9_def_option: [this.saleItemInfo.attrib9_def_option],
      attrib9_allow_multiselect: [this.saleItemInfo.attrib9_allow_multiselect],
      attrib10_name: [this.saleItemInfo.attrib10_name],
      attrib10_options: [this.saleItemInfo.attrib10_options],
      attrib10_def_option: [this.saleItemInfo.attrib10_def_option],
      attrib10_allow_multiselect: [this.saleItemInfo.attrib10_allow_multiselect],
      attrib11_name: [this.saleItemInfo.attrib11_name],
      attrib11_options: [this.saleItemInfo.attrib11_options],
      attrib11_def_option: [this.saleItemInfo.attrib11_def_option],
      attrib11_allow_multiselect: [this.saleItemInfo.attrib11_allow_multiselect],
      attrib12_name: [this.saleItemInfo.attrib12_name],
      attrib12_options: [this.saleItemInfo.attrib12_options],
      attrib12_def_option: [this.saleItemInfo.attrib12_def_option],
      attrib12_allow_multiselect: [this.saleItemInfo.attrib12_allow_multiselect],
      short_name: [this.saleItemInfo.short_name],
      item_type: ['1'],
      is_open: [this.saleItemInfo.is_open],
      item_cost: [this.saleItemInfo.item_cost],
      bg_color2: [this.saleItemInfo.bg_color2],
      is_dt_item: [this.saleItemInfo.is_dt_item],
      selectedStock: [this.selectedStock],
    });

    if (this.saleItemInfo.stock_item_id) {
      this.editForm.get('stock_item_id')?.setValue(this.saleItemInfo.stock_item_id);
    }

    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (saleItemId !== null && parseInt(saleItemId) !== 0) {
      this.getSaleItemInfo(saleItemId);
      // this.getChoiceInfo(saleItemId);
    }
  }

  ngOnInit() {
    this.getTitle()
    // this.loadStockList();
    this.loadKitchenList();
    this.loadItemClassesList();
    this.loadGroupItemList();
    this.loadTaxList();
    this.loadUomList();
    this.loadChoiceList();
    this.myControl.valueChanges.subscribe(() => {
      this.filter();
    });
  }

  onInputBlur(): void {
    // Add any additional logic you need on blur
  }

  openChoiceBrowseDialog(): void {
    if (this.selectedChoiceList !== null && this.selectedChoiceList !== undefined && this.selectedChoiceList.length !== 0) {
      this.choiceList = this.choiceList.filter((choice: any) =>
        !this.selectedChoiceList.some((selectedChoice: any) => selectedChoice.choice_id === choice.id)
      );
    }
    const editorConfig = {
      title: "Choice",
      items: this.choiceList,
      selectedItemId: this.saleItemInfo.id,
    };

    const dialogRef = this.dialog.open(ChoiceListComponent, {
      width: "30%",
      height: "80%", // Adjust the diamensions as needed
      data: editorConfig,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        const selectedItems = result.selectedItems; // Store selected items in the component property
        if (this.selectedChoiceList !== null && this.selectedChoiceList !== undefined && this.selectedChoiceList.length !== 0) {
          selectedItems.forEach((item2: any) => {
            if (!this.selectedChoiceList.some((item1: any) => item1.choice_id === item2.choice_id)) {
              this.selectedChoiceList.push(item2);
            }
          });
        } else {
          selectedItems.forEach((item2: any) => {
            this.selectedChoiceList.push(item2);
          });
        }
      }
    });
    console.log(this.selectedChoiceList)
  }
  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedStockName = event.option.value;
    this.selectedStock = this.stock_item_id.find((stock: { name: string; code: string; id: string }) => stock.name === selectedStockName);

    if (this.selectedStock) {
      this.selectedCode = this.selectedStock.code;
      this.selectedId = this.selectedStock.id;
      this.myControl.setValue(this.selectedStock.name);
      this.editForm.get('stock_item_id')?.setValue(this.selectedStock.id);
    }
  }

  displayStockItem(stock: { name: string, id: string } | null): string {
    return stock ? stock.name : '';
  }

  trackById(index: number, stock: { id: string; }): string {
    return stock.id;
  }

  filter(): void {
    const filterValue = this.myControl.value?.toLowerCase() || '';
    this.filteredOptions = this.stock_item_id.filter((stock: any) =>
      stock.name.toLowerCase().includes(filterValue)
    );
  }

  toggleColorInput(checked: boolean): void {
    this.isMultiColorChecked = checked;
    this.isSecondColorEditable = checked;
  }

  isDirty(): boolean {
    return this.isFormDirty;
  }

  navBack(): void {
    this.router.navigate(['/sale-items']);
  }

  getSaleItemInfo(saleItemId: any): void {
    this.saleItemService.getSaleItemInfoById(saleItemId).subscribe(
      (response: any) => {
        if (response.status == 'SUCCESS') {
          this.saleItemData = response.data;
          this.selectedChoiceList = response.data.sale_items.choices;
          // console.log(response.data)
          this.setSaleItemData(response.data.sale_items);
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });
  }

  setSaleItemData(saleItem: any): void {
    this.saleItemInfo.id = saleItem.id;
    this.saleItemInfo.code = saleItem.code;
    this.saleItemInfo.name = saleItem.name;
    this.saleItemInfo.alternative_name = saleItem.alternative_name;
    this.saleItemInfo.alternative_name_to_print = saleItem.alternative_name_to_print;
    this.saleItemInfo.name_to_print = saleItem.name_to_print;
    this.saleItemInfo.barcode = saleItem.barcode;
    this.saleItemInfo.group_item_id = saleItem.group_item_id;
    this.saleItemInfo.sub_class_id = saleItem.sub_class_id;
    this.saleItemInfo.display_order = saleItem.display_order;
    this.saleItemInfo.fg_color = saleItem.fg_color;
    this.saleItemInfo.bg_color = saleItem.bg_color;
    this.saleItemInfo.fixed_price = saleItem.fixed_price;
    this.saleItemInfo.tax_id = saleItem.tax_id;
    this.saleItemInfo.uom_id = saleItem.uom_id;
    this.saleItemInfo.kitchen_id = saleItem.kitchen_id;
    this.saleItemInfo.is_hot_item_1 = saleItem.is_hot_item_1;
    this.saleItemInfo.is_hot_item_2 = saleItem.is_hot_item_2;
    this.saleItemInfo.is_hot_item_3 = saleItem.is_hot_item_3;
    this.saleItemInfo.hot_item_1_display_order = saleItem.hot_item_1_display_order;
    this.saleItemInfo.hot_item_2_display_order = saleItem.hot_item_2_display_order;
    this.saleItemInfo.hot_item_3_display_order = saleItem.hot_item_3_display_order;
    this.saleItemInfo.is_manufactured = saleItem.is_manufactured;
    this.saleItemInfo.is_require_weighing = saleItem.is_require_weighing;
    this.saleItemInfo.is_valid = saleItem.is_valid;
    this.saleItemInfo.attrib1_name = saleItem.attrib1_name;
    this.saleItemInfo.attrib1_options = saleItem.attrib1_options;
    this.saleItemInfo.attrib1_def_option = saleItem.attrib1_def_option;
    this.saleItemInfo.attrib1_allow_multiselect = saleItem.attrib1_allow_multiselect;
    this.saleItemInfo.attrib2_name = saleItem.attrib2_name;
    this.saleItemInfo.attrib2_options = saleItem.attrib2_options;
    this.saleItemInfo.attrib2_def_option = saleItem.attrib2_def_option;
    this.saleItemInfo.attrib2_allow_multiselect = saleItem.attrib2_allow_multiselect;
    this.saleItemInfo.attrib3_name = saleItem.attrib3_name;
    this.saleItemInfo.attrib3_options = saleItem.attrib3_options;
    this.saleItemInfo.attrib3_def_option = saleItem.attrib3_def_option;
    this.saleItemInfo.attrib3_allow_multiselect = saleItem.attrib3_allow_multiselect;
    this.saleItemInfo.attrib4_name = saleItem.attrib4_name;
    this.saleItemInfo.attrib4_options = saleItem.attrib4_options;
    this.saleItemInfo.attrib4_def_option = saleItem.attrib4_def_option;
    this.saleItemInfo.attrib4_allow_multiselect = saleItem.attrib4_allow_multiselect;
    this.saleItemInfo.attrib5_name = saleItem.attrib5_name;
    this.saleItemInfo.attrib5_options = saleItem.attrib5_options;
    this.saleItemInfo.attrib5_def_option = saleItem.attrib5_def_option;
    this.saleItemInfo.attrib5_allow_multiselect = saleItem.attrib5_allow_multiselect;
    this.saleItemInfo.attrib6_name = saleItem.attrib6_name;
    this.saleItemInfo.attrib6_options = saleItem.attrib6_options;
    this.saleItemInfo.attrib6_def_option = saleItem.attrib6_def_option;
    this.saleItemInfo.attrib6_allow_multiselect = saleItem.attrib6_allow_multiselect;
    this.saleItemInfo.attrib7_name = saleItem.attrib7_name;
    this.saleItemInfo.attrib7_options = saleItem.attrib7_options;
    this.saleItemInfo.attrib7_def_option = saleItem.attrib7_def_option;
    this.saleItemInfo.attrib7_allow_multiselect = saleItem.attrib7_allow_multiselect;
    this.saleItemInfo.attrib8_name = saleItem.attrib8_name;
    this.saleItemInfo.attrib8_options = saleItem.attrib8_options;
    this.saleItemInfo.attrib8_def_option = saleItem.attrib8_def_option;
    this.saleItemInfo.attrib8_allow_multiselect = saleItem.attrib8_allow_multiselect;
    this.saleItemInfo.attrib9_name = saleItem.attrib9_name;
    this.saleItemInfo.attrib9_options = saleItem.attrib9_options;
    this.saleItemInfo.attrib9_def_option = saleItem.attrib9_def_option;
    this.saleItemInfo.attrib9_allow_multiselect = saleItem.attrib9_allow_multiselect;
    this.saleItemInfo.attrib10_name = saleItem.attrib10_name;
    this.saleItemInfo.attrib10_options = saleItem.attrib10_options;
    this.saleItemInfo.attrib10_def_option = saleItem.attrib10_def_option;
    this.saleItemInfo.attrib10_allow_multiselect = saleItem.attrib10_allow_multiselect;
    this.saleItemInfo.attrib11_name = saleItem.attrib11_name;
    this.saleItemInfo.attrib11_options = saleItem.attrib11_options;
    this.saleItemInfo.attrib11_def_option = saleItem.attrib11_def_option;
    this.saleItemInfo.attrib11_allow_multiselect = saleItem.attrib11_allow_multiselect;
    this.saleItemInfo.attrib12_name = saleItem.attrib12_name;
    this.saleItemInfo.attrib12_options = saleItem.attrib12_options;
    this.saleItemInfo.attrib12_def_option = saleItem.attrib12_def_option;
    this.saleItemInfo.attrib12_allow_multiselect = saleItem.attrib12_allow_multiselect;
    this.saleItemInfo.choices = this.selectedChoiceList;
  }

  deleteFromSelectedChoice(choiceId: any) {
    console.log(this.selectedChoiceList)
    this.choiceList.forEach((item1: any) => {
      const index = this.selectedChoiceList.findIndex((selectedChoice: any) => selectedChoice.choice_id === choiceId);
      if (index !== -1) {
        // Remove item from array2 and add it to array1
        const removedItem = this.selectedChoiceList.splice(index, 1)[0];
        this.choiceList.push(removedItem);
      }
    });
    // this.selectedChoiceList = this.selectedChoiceList.filter((choice:any) => choice.choice_id !== choiceId);
  }
  getTitle(): string {
    return (this.saleItemInfo.id == undefined) ? "New Sale Item" : "Editing : " + this.saleItemInfo.name;
  }

  loadStockList(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 100, offset: 0 },
        },
        sort: [{ column: "code", order: "asc" }],
      },
    };

    this.saleItemService.getStocksList(requestData).subscribe(
      (response: {
        data: any;
        status: string;
        message: any;
      }) => {
        if (response.status === "SUCCESS") {
          this.stock_item_id = response.data.stocks;
          this.filteredOptions = this.stock_item_id;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      }
    );
  }

  loadKitchenList(): void {
    this.isLoading = true;
    let requestData = { "search": { "filters": { "scope": { "limit": 10, "offset": 0 } }, "sort": [] } };
    let response = this.kitchenService.getKitchenList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.kitchenList = response.data.kitchens;
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });
  }

  loadItemClassesList(): void {
    this.isLoading = true;
    let requestData = { "search": { "filters": { "simpleSearch": [], "advSearch": [], "scope": { "limit": 10, "offset": 0 } }, "sort": [{ "column": "item_classes.name", "order": "asc" }] } };
    let response = this.itemService.getList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.itemCategoryList = response.data.item_classes;
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });
  }

  loadGroupItemList() {
    this.isLoading = true;
    let requestData = { "search": { "filters": { "scope": { "limit": 10, "offset": 0 } }, "sort": [] } };
    let response = this.saleItemService.getList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.groupItemList = response.data.item_classes;
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });
  }

  loadTaxList(): void {
    this.isLoading = true;
    let requestData = { "search": { "filters": { "scope": { "limit": 10, "offset": 0 } }, "sort": [] } };
    let response = this.saleItemService.getList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.taxList = response.data.item_classes;
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    })
  }

  loadUomList() {
    this.isLoading = true;
    let requestData = { "search": { "filters": { "scope": { "limit": 10, "offset": 0 } }, "sort": [] } };
    let response = this.uomService.getUomList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.uomList = response.data.uoms;
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    })
  }

  loadChoiceList(): void {
    this.isLoading = true;
    let requestData = { "search": { "filters": { "scope": { "limit": 10, "offset": 0 } }, "sort": [] } };
    let response = this.choiceService.getChoiceList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.choiceList = response.data.choices;
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });
  }

  // getChoiceInfo(saleItemId:any){
  //   this.isLoading = true;
  //   // let requestData = {"search":{"filters":{"scope":{"limit":10,"offset":0}},"sort":[]}};
  //   let response = this.saleItemService.getChoiceList(saleItemId).subscribe(response => {
  //     if (response.status == 'SUCCESS') {
  //       this.selectedChoiceList = response.data.choices;
  //       console.log(this.selectedChoiceList);
  //     } else {
  //       let snackBarData: SnackBarData = {
  //         message: response.message,
  //         title: 'Failed!!!',
  //         type: 'error'
  //       }
  //       this.snackBarService.openSnackBar(snackBarData);
  //     }
  //     this.isLoading = false;
  //   });
  // }

  save(): void {
    if (this.editForm.valid) {
      this.editForm.markAllAsTouched();
      Object.assign(this.saleItemInfo, this.editForm.value);
      this.saleItemService.update(this.saleItemInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.saleItemInfo.name + " has been updated successfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
            this.router.navigate(['/sale-items']);

          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });
    } else {
      this.editForm.markAllAsTouched();
    }
  }
}