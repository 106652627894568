<h1 mat-dialog-title class="edit-form-title fixed-title">Sale Items</h1>
<mat-dialog-content  class="edit-form-content">
  <mat-form-field   class="fixed-filter">
    <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
  </mat-form-field>
  <mat-checkbox [(ngModel)]="showSelected" (change)="showSelectedItems()">Show selected items only</mat-checkbox>

  <div class="scrollable-content">
    <table mat-table [dataSource]="dataSource" >

      <!-- Select Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? toggleAllRows() : null"
            [checked]="isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" [checked]="isRowSelected(row)" (change)="selectRow(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Code Column -->
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>Code</th>
        <td mat-cell *matCellDef="let item">{{ item.code }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let item">{{ item.name }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="save()">OK</button>
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
