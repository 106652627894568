
import { Component, ViewChild } from '@angular/core';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { TableLocationModel } from './model/table.model';
import { TableLocationService } from './table-location.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterListItemModel, SearchFilterComponent, SearchParam } from 'src/app/common/components/search-filter/search-filter.component';
import { Router } from '@angular/router';
import { StateManagerService } from 'src/app/common/services/state.manager.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-table-location',
  templateUrl: './table-location.component.html',
  styleUrl: './table-location.component.scss'
})
export class TableLocationComponent {
  tableList: TableLocationModel[]=[];
  imageList:any;
    
      showSearch: boolean = false;
      isLoading = true;
      filter: FilterListItemModel[] = [
        {
          title: "Code",
          column: "code",
        },
        {
          title: "Name",
          column: "name",
        },
        {
          title: "Description",
          column: "description",
        },
        
      ];
      displayedColumns = ['code', 'name', 'description','table','action'];
      pagingData = { length: 0, pageSize: 20, pageIndex: 0 };
      menuOption: FloatingMenuOption = {
        add: true,
        search: false,
        export: true,
        more: true
      }
    
      isHandset: boolean = false;
      searchColumFilter: SearchParam = {};
      columnSortData: SearchSort[] = [];
      selectedItem?: TableLocationModel;
      showSummaryPanel: boolean = false;
      selection = new SelectionModel<TableLocationModel>(true, []);
      dataSource = new MatTableDataSource(this.tableList);
      IsDownloading: boolean=false;
    
     constructor(private breakpointObserver: BreakpointObserver,
      private tableService: TableLocationService,
      public dialog: MatDialog,
      private snackBarService: SnackBarService,
      private router: Router,  private stateManagerService: StateManagerService,
    ) {
    
      this.tableList = [];
      this.imageList = [];
      
    }
     /**
       * initilaize the device model
       */
     ngOnInit(): void {
      this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isHandset = true;
        } else {
          this.isHandset = false;
        }
      });
      this.isLoading = false; // Set to true on page load
      //this.loadData();
      this.tableImageData()
    }
     /** 
      * Set the datasource
      */
     setDataSource(tableList:TableLocationModel[]){
      this.dataSource=new MatTableDataSource(tableList);
      this.dataSource.sort=this.sort;
    }
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild('searchFilter') searchFilter!: SearchFilterComponent;
    ngAfterViewInit(): void {
    
      this.loadPreviousState();
      // this.loadData();
    }
    
    /**
     * loads the previous state if exists
     */
    loadPreviousState(): void {
    
      var previousState = this.stateManagerService.getState(this.constructor.name);
    
      if (previousState !== undefined && this.searchFilter != undefined) {
    
        if (previousState.state.search.filters.scope) {
          this.pagingData.pageIndex = previousState.state.search.filters.scope.offset / previousState.state.search.filters.scope.limit;
          this.pagingData.pageSize = previousState.state.search.filters.scope.limit;
        }
    
        if (previousState.state.search.sort != undefined && previousState.state.search.sort.length > 0) {
          this.setSortColumn(previousState.state.search.sort[0].column,previousState.state.search.sort[0].order);
        }
    
        this.searchColumFilter = JSON.parse(JSON.stringify(previousState.state.search.filters));
        this.searchFilter.setSearchFilter(this.searchColumFilter,true);
    
      } else {
        this.setSortColumn('name','asc');
        this.loadData();
      }
    
    }

      /**
       * Sets the sort column
       * @param column 
       * @param direction 
       */
      setSortColumn(column: string, direction: SortDirection): void {
        this.columnSortData.length = 0;
        this.columnSortData.push({ column: column, order: direction});
        this.sort.sort({
          id: column,
          start: direction,
          disableClear: false,
        });
      }
    
      isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.tableList.length;
        return numSelected === numRows;
      }
    
      toggleAllRows() {
        if (this.isAllSelected()) {
          this.selection.clear();
          return;
        }
    
        this.selection.select(...this.tableList);
      }
    
      /**
       * Create sort order
       * @returns sort criteria
       */
      getSort(): SearchSort[] {
        return this.columnSortData;
      }
    
    /**
       * Builds the request data to be send to API
       * @returns Request Data
       */
    buildRequestData(): RequestData {
      let searchParam = this.getSearchParam();
      let requestData: RequestData = {
        search: {
          filters: {
            simpleSearch: searchParam.freeSearch,
            advSearch: searchParam.advSearch,
            scope: {
              limit: this.pagingData.pageSize,
              offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
            }
          },
          sort: this.getSort(),
        }
      };
    
      return requestData;
    }
    
     /**
       * Set the column filter and reaload the daaa
       * @param columFilter 
       */
    
     onFilterApplied(columFilter: any): void {
      this.searchColumFilter = columFilter;
      this.loadData();
    
    }

     /**
       * On delete menu item selected
       * Confirm the action and call the api to update
       */
     onDelete(tableInfo:TableLocationModel): void {
      const tableId = tableInfo.id;
      const message = `Are you sure, you want to delete ` + (tableInfo.name + "?");
      const dialogData = new ConfirmDialogModel("Confirm Deletion", message);
    
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: dialogData
      });
    
      dialogRef.afterClosed().subscribe(dialogResult => { 
        if (dialogResult) {
          this.tableService.delete(tableInfo).subscribe({
            next: (response) => {
              if (response.status == 'SUCCESS') {
                // handle success
                let snckBarData: SnackBarData = {
                  type: 'success',
                  title: 'Saved',
                  message: 'The record for ' + tableInfo.name + " has been deleted succesfully."
                }
                this.snackBarService.openSnackBar(snckBarData);
                this.loadData();
              } else {
                let snackBarData: SnackBarData = {
                  message: response.message,
                  title: 'Failed!!!',
                  type: 'error'
                }
                this.snackBarService.openSnackBar(snackBarData);
              }
            },
            error: (error) => {
              let snackBarData: SnackBarData = {
                message: 'Failed to update. Connection to the server failed',
                title: 'Failed!!!',
                type: 'error'
              }
              this.snackBarService.openSnackBar(snackBarData);
            }
          });
        }
      });
    }
    
    /**
     * loads the data based on the conditions
     */
    loadData(): void {
      this.isLoading = true;
      let requestData = this.buildRequestData();
      let response = this.tableService.getTableList(requestData).subscribe(response => {
        if (response.status == 'SUCCESS') {
          this.setDataSource(response.data.tables);
          this.pagingData.length = response.data.total_count;
          if (this.pagingData.length == 0) {
            let snackBarData: SnackBarData = {
              message: 'No data found. Please remove or change the filter if any.',
              title: 'No Data!!!',
              type: 'warn'
            }
            this.snackBarService.openSnackBar(snackBarData);
            this.selectedItem = undefined;
          } else {
            this.onRowSelected(this.tableList[0])
            this.stateManagerService.setState(this.constructor.name, requestData);
          }
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
        this.isLoading = false;
      });
    
    }
    /**
     * to get the table-image data 
     */
    tableImageData(): void {
      let requestData = this.buildRequestData();
      this.tableService.getTableimage(requestData).subscribe(response => {
        if (response.status == 'SUCCESS') {
          // this.setDataSource(response.data.tables);
          this.imageList = response.data.images;
          // Store imageList in session storage
          sessionStorage.setItem('imageList', JSON.stringify(this.imageList));
          this.pagingData.length = response.data.total_count;
          if (this.pagingData.length == 0) {
            let snackBarData: SnackBarData = {
              message: 'No data found. Please remove or change the filter if any.',
              title: 'No Data!!!',
              type: 'warn'
            };
            this.snackBarService.openSnackBar(snackBarData);
            this.selectedItem = undefined;
          } else {
            this.onRowSelected(this.tableList[0]);
            this.stateManagerService.setState(this.constructor.name, requestData);
          }
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
        this.isLoading = false;
      });
    }
    
    
     /**
     * On tableinfo selected
     * @param tableInfo 
     */
     onRowSelected(tableInfo: TableLocationModel): void {
      this.selectedItem = tableInfo;
      this.showSummaryPanel = true;
    }
    
     /**
       *  Set the sort param and relead the data
       * @param $event sort data
       * {"active": "email","direction": "asc"}
       *   
       */
     sortData($event: any): void {
      var sd=this.sort;
      this.columnSortData.length = 0;
      this.columnSortData.push({ column: sd.active, order: sd.direction });
      this.loadData();
    }
      /**
    * Creates the column filter conditions based on search criteria
    * @returns Filter condions for columns
    */
    getSearchParam(): SearchParam {
    
      return this.searchColumFilter;
    }
    
      /**
     * On edit the item
     * Show the edit dialog
     */
      onEdit(tableInfo: TableLocationModel): void {
    
        this.router.navigate(['table-location-edit', tableInfo.id]);
      }
    
      
      navigateToTableLocationImage(tableId: number): void {
        this.router.navigate(['table-edit', tableId]);
      }
      /**
       * 
       * @param event 
       * When page size is changed update paging data
       */
      onePageEvent(event: any): void {
    
        this.pagingData.pageIndex = event.pageIndex;
        this.pagingData.pageSize = event.pageSize;
    
        this.loadData();
    
      }
    
      /**
       * On Add clicked
       * Show the dialog to enter new table
       */
      addClicked(): void {
    
        this.router.navigate(['table-location-edit', 0]);
    
      }
    
      exportClicked() {
        this.isLoading = true;
        let requestData = this.buildRequestData();
        this.IsDownloading = true;
        this.tableService.downloadExcel(requestData)
          .subscribe({
            next: blob => {
              // Download logic
              this.IsDownloading = false;
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = 'region_list.csv'; // Adjust filename if needed
              link.click();
              window.URL.revokeObjectURL(url);
              this.isLoading = false;
    
    
            },
            error: error => {
              // Handle errors
              this.IsDownloading = false;
              let snackBarData: SnackBarData = {
                message: 'Failed to update. Connection to the server failed',
                title: 'Failed!!!',
                type: 'error'
              }
              this.snackBarService.openSnackBar(snackBarData);
              this.isLoading = false;
              // Display user-friendly error message to the user
            }
          }
          );
      }
    

}
