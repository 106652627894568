<div class="edit-page-root-container shop-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
            </div>
        </div>
        <div class="page-details">
            <div class="detail-section">
                <div class="content-container">
                    <form class="edit-form" [formGroup]="editForm">
                        <div class="input-group" style="display:flex;gap:10px;">
                            <div class="input-box-left"> <mat-form-field class="code">
                                    <mat-label>Code</mat-label>
                                    <input matInput formControlName="code" maxlength="50" [(ngModel)]="menuInfo.code"
                                        [readonly]="menuInfo.id!==undefined && menuInfo.id!==0">
                                    <mat-error *ngIf="editForm.controls['code'].hasError('required')">Shop code should
                                        be
                                        entered</mat-error>
                                    <mat-error *ngIf="editForm.controls['code'].hasError('maxlength')">Maximum length is
                                        10.</mat-error>
                                </mat-form-field></div>

                            <div class="input-box-right" style="flex:1;"> <mat-form-field class="name"
                                    style="width:100%;">
                                    <mat-label>Name</mat-label>
                                    <input matInput formControlName="name" [(ngModel)]="menuInfo.name">
                                    <mat-error *ngIf="editForm.controls['name'].hasError('required')">Shop name should
                                        be
                                        entered</mat-error>
                                    <mat-error *ngIf="editForm.controls['name'].hasError('maxlength')">Maximum length is
                                        50.</mat-error>
                                </mat-form-field></div>

                        </div>


                        <mat-form-field class="description" style="width:100%;">
                            <mat-label>Description</mat-label>
                            <textarea matInput formControlName="description"
                                [(ngModel)]="menuInfo.description"></textarea>
                            <mat-error *ngIf="editForm.controls['description'].hasError('maxlength')">Maximum length is
                                200.</mat-error>
                        </mat-form-field>

                    </form>

                    <table matSort mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                        <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->

                        <!-- Position Column -->
                        <ng-container matColumnDef="no">
                            <th mat-header-cell *matHeaderCellDef> No. </th>
                            <td mat-cell *matCellDef="let department; let i = index"> {{ i + 1 }} </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="code">
                            <th mat-header-cell *matHeaderCellDef> Code </th>
                            <td mat-cell *matCellDef="let department"> {{department.code}} </td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let department"> {{department.name}} </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef> Select </th>
                            <td mat-cell *matCellDef="let department">
                                <mat-checkbox class="example-margin"
                                    [(ngModel)]="selectedDpt[department.id]" (change)="DptChange($event, department)"></mat-checkbox>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                        </tr>
                        <tr mat-row class="table-element-row" *matRowDef="let department; columns: displayedColumns;"
                            (click)="onRowSelected(department)"
                            [ngClass]=" {'row-selected' : department.id===selectedItem?.id}">
                        </tr>
                    </table>
                    <mat-form-field class="description" style="width:20%;margin-top: 15px;">
                        <mat-label>Color</mat-label>
                        <input matInput type="color" formControlName="fg_color" [(ngModel)]="menuInfo.color">
                    </mat-form-field>
                    <div class="d-flex content-container" style="margin-top: 15px;">
                        <mat-checkbox [(ngModel)]="menuInfo.is_default_menu">
                            Is Default Menu
                        </mat-checkbox>
                        <mat-checkbox [(ngModel)]="menuInfo.enable_h1_button">
                            Enable H1 Button
                        </mat-checkbox>
                        <mat-checkbox [(ngModel)]="menuInfo.enable_h2_button">
                            Enable H2 Button
                        </mat-checkbox>
                        <mat-checkbox [(ngModel)]="menuInfo.enable_h3_button">
                            Enable H3 Button
                        </mat-checkbox>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
