import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ChoiceService } from '../choice.service';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';

@Component({
  selector: 'app-sale-item-dialog',
  templateUrl: './sale-item-dialog.component.html',
  styleUrls: ['./sale-item-dialog.component.scss'],
})
export class SaleItemDialogComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['select', 'code', 'name'];
  selection: SelectionModel<any>;
  showSelected: boolean = false;
  selectedItems: any;
  

  // Output event to emit selected items back to the parent component
  @Output() selectedItemsChange = new EventEmitter<any[]>();
  choiceId: any;


  constructor(
    public dialogRef: MatDialogRef<SaleItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ChoiceService: ChoiceService,
    private snackBarService: SnackBarService,
    
  ) {
    this.dataSource = new MatTableDataSource<any>(data.items);
    this.selection = new SelectionModel<any>(true, data.selectedItems ?? []);
    this.choiceId = data.choiceId; 
  }

  ngOnInit(): void {
    // Add any initialization logic if needed
    if (this.data.choiceId) {
      console.log('Choice ID in Dialog:', this.data.choiceId);
      // Use the choiceId for any specific logic, like filtering the saleitemList
    }
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  isRowSelected(row: any): boolean {
    return this.selection.isSelected(row);
  }

  selectRow(row: any): void {
    this.selection.toggle(row);
  }

  showSelectedItems(): void {
    if (this.showSelected) {
      this.dataSource.data = this.dataSource.data.filter(item => this.isRowSelected(item));
    } else {
      this.dataSource = new MatTableDataSource<any>(this.data.saleitemList);
    }
  }

  // save(): void {
  //   const selectedItems = this.selection.selected;
  //   this.dialogRef.close({ selectedItems });
  // }
  save(): void {
    this.selectedItems = this.selection.selected.map(
      (item: any) => {
        // let rw = this.data.selectedItems.find((item: any) => item.crm_reward_id === choice.id)
        // if (rw) {
          return {
            id: item.id,
            code:item.code,
            name:item.name,
            is_deleted:0,
          };
        // }
      });
    // if(this.data.selectedItems.length==0){
    //   this.selectedItems = this.selection.selected.map(
    //     (reward: any) => {
    //         return {
    //           crm_reward_id: reward.id,
    //           count:0 ,
    //         };
    //       }
    //   );
    // }
    // else{
    //   this.selectedItems = this.selection.selected.map(
    //     (reward: any) => {
    //       let rw=this.data.selectedItems.find((item:any) =>item.crm_reward_id===reward.id)
    //       if(rw){
    //         return {
    //           crm_reward_id: reward.id,
    //           count:rw.count ,
    //         };
    //       }else{
    //         return {
    //           crm_reward_id: reward.id,
    //           count:0 ,
    //         };
    //       }
    //     }
    //   );
    // // }
    console.log(this.selectedItems)
      this.dialogRef.close({ selectedItems: this.selectedItems });
  }
  // save(): void {
  //   // Get the selected item IDs from the selection
  //   const selectedIds = this.selection.selected.map((item) => item.id);
  
  //   // Ensure the selected IDs are valid
  //   if (selectedIds.length === 0) {
  //     this.snackBarService.openSnackBar({
  //       type: 'error',
  //       title: 'Error',
  //       message: 'Please select at least one item.',
  //       duration: 3000,
  //     });
  //     return;
  //   }
  
  //   // Prepare the payload, including the choiceId and the selected item IDs
  //   const payload = {
  //     choiceId: this.choiceId, // Add the choiceId (which will be added to choice_ids in sale_items)
  //     saleItemIds: selectedIds, // Add selected sale item IDs
  //   };
  
  //   // Call the service to update the sale items with the choiceId
  //   this.ChoiceService.updateChoiceWithItems(this.choiceId, selectedIds).subscribe(
  //     (response) => {
  //       if (response.status === 'SUCCESS') {
  //         this.snackBarService.openSnackBar({
  //           type: 'success',
  //           title: 'Success',
  //           message: 'Choice ID added successfully to selected items.',
  //           duration: 3000,
  //         });
  //       } else {
  //         this.snackBarService.openSnackBar({
  //           type: 'error',
  //           title: 'Error',
  //           message: response.message || 'Failed to update sale items.',
  //           duration: 3000,
  //         });
  //       }
  //     },
  //     (error) => {
  //       console.error('Error updating sale items:', error);
  //       this.snackBarService.openSnackBar({
  //         type: 'error',
  //         title: 'Error',
  //         message: 'An error occurred while updating sale items.',
  //         duration: 3000,
  //       });
  //     }
  //   );
  //   this.dialogRef.close({ });
  // }
  

  cancel(): void {
    this.dialogRef.close();
  }

}
