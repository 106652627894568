import { Injectable } from '@angular/core';
import { ChoiceModel } from './model/choice.model';
import { RequestData } from 'src/app/common/models/request-data.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ChoiceService {

 constructor(private http: HttpClient) { }
 
   getChoiceList(requestData:RequestData): Observable<any> {
     return this.http.post(environment.baseUrl+'/choice/get', requestData);
 
   }
 
   getChoiceById(id:number): Observable<any> {
     return this.http.post(environment.baseUrl+'/choice/get/'+id, '');
 
   }
   getSaleItemById(id:number): Observable<any> {
    return this.http.post(environment.baseUrl+'/choice/getsale/'+id, '');

  }
  getSalesItemById(id:number): Observable<any> {
    return this.http.post(environment.baseUrl+'/choice/getsaleitems/'+id, '');

  }
 
    /**
    * 
    * @param choiceInfo 
    * Calls the update API to insert/update the record
    */
    update(choiceInfo: ChoiceModel): Observable<any> { 
     if(choiceInfo.hasOwnProperty("id")){
       return this.http.post(environment.baseUrl+'/choice/update', choiceInfo);
     } else{
       return this.http.post(environment.baseUrl+'/choice/add', choiceInfo);
     }
   }
   updateChoiceWithItems(choiceId: number, saleItemIds: any[]): Observable<any> {
    return this.http.post(environment.baseUrl+'/choice/updatesaleitems', { choiceId, saleItemIds });
  }
 
     /**
    * 
    * @param choiceInfo 
    * Calls the API to delete the record
    */
     delete(choiceInfo: ChoiceModel): Observable<any>  {
       return this.http.post(environment.baseUrl+'/choice/delete/'+choiceInfo.id, choiceInfo);
     }
 
     downloadExcel(requestData: any): Observable<Blob> {
       const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL
   
       return this.http.post(environment.baseUrl + '/hq/area/export-region-list', requestData, { headers, responseType: 'blob' })
         .pipe(
           //catchError(this.handleError)
         );
   
     }
     

     updateSalesItems(payload: { choiceId: number; saleItemIds: number[] }): Observable<any> {
      const url = `/api/sale-items/update`;
      return this.http.post(url, payload);
    }
}
