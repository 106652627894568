<div class="edit-page-root-container area-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
            </div>
        </div>
        <div class="page-details">
            <form class="edit-form" [formGroup]="editForm">
                <div class="detail-section">
                    <div class="content-container">
                        <div class="input-group">
<!--                             
                            <div class="input-box-left"> 
                                <mat-form-field class="code">
                                <mat-label>Code</mat-label>
                                <input matInput formControlName="code" maxlength="50"
                                    [readonly]="employeeInfo.id!==undefined && employeeInfo.id!==0">
                                <mat-error *ngIf="editForm.controls['code'].hasError('required')">employee code should be
                                    entered</mat-error>
                                <mat-error *ngIf="editForm.controls['code'].hasError('maxlength')">Maximum length is
                                    10.</mat-error>
                            </mat-form-field>
                            </div> -->

                           

                        </div>
                           
                        <div class="input-content">
                            <mat-form-field class="input-container">
                                <mat-label>Code</mat-label>
                                <input matInput formControlName="code" maxlength="50"
                                    [readonly]="employeeInfo.id!==undefined && employeeInfo.id!==0">
                                <mat-error *ngIf="editForm.controls['code'].hasError('required')">employee code should be
                                    entered</mat-error>
                                <mat-error *ngIf="editForm.controls['code'].hasError('maxlength')">Maximum length is
                                    10.</mat-error>
                            </mat-form-field>
                            <mat-form-field class="input-container">
                                <mat-label>First Name</mat-label>
                                <input matInput formControlName="f_name">
                                <mat-error *ngIf="editForm.controls['f_name'].hasError('required')">Area name should be
                                    entered</mat-error>
                                <mat-error *ngIf="editForm.controls['f_name'].hasError('maxlength')">Maximum length is
                                    50.</mat-error>
                            </mat-form-field>
                            <mat-form-field class="input-container" >
                                    <mat-label>Last name</mat-label>

                                    <input matInput formControlName="l_name">
                                </mat-form-field>
                          </div>
                        <div style="margin-bottom: 8px;">
                            <label>Gender:</label>
                            <mat-radio-group formControlName="sex">
                              <mat-radio-button [value]="'F'">Female</mat-radio-button>
                              <mat-radio-button [value]="'M'">Male</mat-radio-button>
                              <mat-radio-button [value]="'o'">Other</mat-radio-button>
                            </mat-radio-group>
                          </div>
                          <div class="input-content">
                            <mat-form-field appearance="fill">
                              <mat-label>Date of Birth</mat-label>
                              <input matInput [matDatepicker]="picker" formControlName="dob" />
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                              <mat-error *ngIf="editForm.controls['dob'].hasError('required')"> date of birth should be
                                entered</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>Date of Joining</mat-label>
                                <input matInput [matDatepicker]="dojPicker" formControlName="doj" />
                                <mat-datepicker-toggle matSuffix [for]="dojPicker"></mat-datepicker-toggle>
                                <mat-datepicker #dojPicker></mat-datepicker>
                                <mat-error *ngIf="editForm.controls['doj'].hasError('required')"> date of join should be
                                    entered</mat-error>
                              </mat-form-field>
                          </div>
                          <mat-form-field class="input-container">
                            <mat-label>Address</mat-label>
                            <textarea matInput formControlName="address"></textarea>
                            <mat-error *ngIf="editForm.controls['address'].hasError('required')"> Address should be
                                entered</mat-error>
                           
                        </mat-form-field>
                        <div class="input-content">
                        <mat-form-field class="selection-container">
                            <mat-label>Department</mat-label>
                            <mat-select formControlName="department_id">
                                <mat-option *ngFor="let employee of departmentList" [value]="employee.id">
                                    {{ employee.name }}
                                </mat-option>
                                
                            </mat-select>
                            <mat-error *ngIf="editForm.controls['department_id'].hasError('required')"> Department should be
                                entered</mat-error>
                        </mat-form-field>
                        <mat-form-field class="selection-container">
                            <mat-label>Catogery</mat-label>
                            <mat-select formControlName="employee_category_id">
                                <mat-option *ngFor="let employee of employeeCatList" [value]="employee.id">
                                    {{ employee.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="editForm.controls['employee_category_id'].hasError('required')"> Catogery should be
                                entered</mat-error>
                        </mat-form-field>
                        </div>
                        <!-- <mat-form-field class="input-container" >
                            <mat-label>Address</mat-label>

                            <input matInput formControlName="address">
                        </mat-form-field> -->
                        
                        
                        <!-- <div class="input-box-right">  <mat-form-field class="name">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="f_name">
                            <mat-error *ngIf="editForm.controls['f_name'].hasError('required')">Area name should be
                                entered</mat-error>
                            <mat-error *ngIf="editForm.controls['f_name'].hasError('maxlength')">Maximum length is
                                50.</mat-error>
                        </mat-form-field></div> -->
                        <div class="input-content">
                            
                          </div>
                        <!-- <div class="input-container">
                            <mat-form-field>
                              <mat-label>Status</mat-label>
                              <mat-select formControlName="status">
                                <mat-option [value]="1">Active</mat-option>
                                <mat-option [value]="2">Resigned</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field class="input-container" >
                                <mat-label>Wage type</mat-label>

                                <input matInput formControlName="wage_type">
                            </mat-form-field>
                          </div> -->
                          <!-- <div class="input-content">
                            <mat-form-field>
                                <mat-label>Status</mat-label>
                                <mat-select formControlName="status">
                                  <mat-option [value]="1">Active</mat-option>
                                  <mat-option [value]="2">Resigned</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field>
                                <mat-label>Card number</mat-label>

                                <input matInput formControlName="work_permit">
                            </mat-form-field>
                              
                          </div> -->
                        
                     
                         
                          <!-- <div class="input-content">
                            
                            <mat-form-field>
                                <mat-label>Wage type</mat-label>
                                <mat-select formControlName="wage_type">
                                  <mat-option [value]="1">Hourly</mat-option>
                                  <mat-option [value]="2">Daily</mat-option>
                                  <mat-option [value]="3">Weekly</mat-option>
                                  <mat-option [value]="4">Monthly</mat-option>
                                </mat-select>
                              </mat-form-field>
                            
                            <mat-form-field class="input-container">
                                <mat-label>Cost per hour</mat-label>
                                <input matInput formControlName="cost_per_hour">
                          
                           
                          </div> -->
                          <!-- <mat-form-field class="example-full-width">
                            <mat-label>Date of Birth</mat-label>
                            <input formControlName="dob" matInput [matDatepicker]="endDatePicker" placeholder="End Date"
                             />
      
                            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                           
                          </mat-form-field> -->
                        <!-- <mat-form-field class="description">
                            <mat-label>Description</mat-label>
                            <textarea matInput formControlName="description"></textarea>
                            <mat-error *ngIf="editForm.controls['description'].hasError('maxlength')">Maximum length is
                                200.</mat-error>
                        </mat-form-field> -->
                        <!-- <div class="input-content">
                            <mat-form-field class="input-container" >
                                <mat-label>Permit number</mat-label>
    
                                <input matInput formControlName="card_no">
                            </mat-form-field>
                           
                           
                        </div> -->
                        <div class="input-group">
                            <div class="input-box-left"> 
                                <mat-form-field>
                                    <mat-label>Status</mat-label>
                                    <mat-select formControlName="status">
                                      <mat-option [value]="1">Active</mat-option>
                                      <mat-option [value]="2">Resigned</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="editForm.controls['status'].hasError('required')"> status should be
                                        entered</mat-error>
                                  </mat-form-field>
                            </div>

                            <div class="input-box-right"> 
                                <mat-form-field class="cost-per-hour">
                                    <mat-label>Card number</mat-label>
    
                                    <input matInput formControlName="card_no">
                                    <mat-error *ngIf="editForm.controls['card_no'].hasError('required')"> Card number type should be
                                        entered</mat-error>
                                </mat-form-field> </div>

                        </div>
                        <div class="input-group">
                            <div class="input-box-left"> 
                                <mat-form-field>
                                    <mat-label>Wage type</mat-label>
                                    <mat-select formControlName="wage_type">
                                      <mat-option [value]="1">Hourly</mat-option>
                                      <mat-option [value]="2">Daily</mat-option>
                                      <mat-option [value]="3">Weekly</mat-option>
                                      <mat-option [value]="4">Monthly</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="editForm.controls['wage_type'].hasError('required')"> Wage type should be
                                        entered</mat-error>
                        
                                  </mat-form-field>
                            </div>

                            <div class="input-box-right"> 
                                <mat-form-field class="cost-per-hour">
                                    <mat-label>Cost per hour</mat-label>
                                    <input matInput formControlName="cost_per_hour">
                                    <mat-error *ngIf="editForm.controls['cost_per_hour'].hasError('required')"> Cost per hour type should be
                                        entered</mat-error>
                                </mat-form-field> </div>

                        </div>
                       
                    </div>
                </div>
             

              


              

            </form>
        </div>
    </div>
</div>