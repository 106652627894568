import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { MenuService } from '../../menu.service';
import { MenuInfoModel, DepartmentModel } from '../../model/menu-settings.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DepartmentService } from 'src/app/pages/system/department/department.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-menu-edit',
  templateUrl: './menu-edit.component.html',
  styleUrl: './menu-edit.component.scss'
})
export class MenuEditComponent {
  title: string = "Menu Settings";
  editForm: FormGroup;
  menuInfo: MenuInfoModel = {
    is_default_menu: 0,
    enable_h1_button: 0,
    enable_h2_button: 0,
    enable_h3_button: 0,
    departments: []
  };
  isFormDirty: boolean = false;
  shoptype: any;
  areaList: any;
  departmentList: DepartmentModel[] = [];
  // displayedColumns: string[] = ['code', 'name'];
  sushishop_list: any;
  mainshop_list: any;
  bento_shop_list: any;
  selected_shop_type: any;
  is_display_bento_shop: boolean = false;
  is_display_dt_shops: boolean = false;
  isDisabled: boolean = false;
  selectedBentoShop: any;
  selectedSushiShop: any;
  mainShop: any;
  shopList: any;
  id: any;
  isLoading = true;


  displayedColumns = ['no', 'code', 'name', 'select'];
  pagingData = { length: 0, pageSize: 10, pageIndex: 0 };

  selectedItem?: DepartmentModel;
  showSummaryPanel: boolean = false;
  dataSource = new MatTableDataSource(this.departmentList);
  menuData: any;

  selectedDpt: { [key: number]: boolean } = {};
  departments: any = [];

  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private menuService: MenuService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private DepartmentService: DepartmentService) {
    // this.loadData();
    // this.departmentList = [];
    let menuId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.menuInfo.code, [Validators.required, Validators.maxLength(10)]),
      name: this.builder.control(this.menuInfo.name, [Validators.required, Validators.maxLength(50)]),
      description: this.builder.control(this.menuInfo.description),
    });

    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });
    if (menuId !== null && parseInt(menuId) !== 0) {
      this.loadMasterData(menuId);
    }
    else {
      this.getDeptInfo();
    }

  }

  ngOnInit(): void {
    // this.loadMasterData();
  }

  setDataSource(departmentList: DepartmentModel[]) {
    this.dataSource = new MatTableDataSource(departmentList);
    this.dataSource.sort = this.sort;
  }

  @ViewChild(MatSort) sort!: MatSort;

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty(): boolean {
    return this.isFormDirty;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {

    this.router.navigate(['/menu']);


  }

  loadMasterData(menuId: any): void {
    // let requestData = {
    //   "search": {
    //     "filters": {
    //       "scope": {
    //         "limit": 10,
    //         "offset": 0
    //       }
    //     },
    //     "sort": [
    //       {
    //         "column": "name",
    //         "order": "asc"
    //       }
    //     ]
    //   }
    // };
    this.menuService.getMenuById(menuId).subscribe(
      (response: any) => {
        if (response.status == 'SUCCESS') {
          this.menuData = response.data;
          // console.log(response.data)
          this.setMenuData(response.data);
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    this.isLoading = true;
    const departmentRequestData = { "search": { "filters": { "scope": { "limit": 10, "offset": 0 } }, "sort": [{ "column": "name", "order": "asc" }] } }
    let response = this.DepartmentService.getDepartmentList(departmentRequestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.departments = response.data.department;
        this.setDataSource(response.data.department);
        if (this.menuData.department != null) {
          this.departments.forEach((item: any) => {
            // Check if the item id is present in comparisonArray
            const match = this.menuData.department.find((dpt: any) => dpt.department_id === item.id);
            this.selectedDpt[item.id] = match ? true : false;
          });
        }
        this.pagingData.length = response.data.total_count;
        if (this.pagingData.length == 0) {
          let snackBarData: SnackBarData = {
            message: 'No data found. Please remove or change the filter if any.',
            title: 'No Data!!!',
            type: 'warn'
          }
          this.snackBarService.openSnackBar(snackBarData);
          this.selectedItem = undefined;
        } else {
          this.onRowSelected(this.departmentList[0])
        }
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });
    // let requestData = {search:{filters:{scope:{limit:100000,offset:0}},sort:[]}}
    //   let requestData = {
    //     "search": {
    //         "filters": {
    //             "scope": {
    //                 "limit": 10,
    //                 "offset": 0
    //             }
    //         },
    //         "sort": [
    //             {
    //                 "column": "name",
    //                 "order": "asc"
    //             }
    //         ]
    //     }
    // };
    // this.ShopInfoService.getShopList(requestData).subscribe(
    //   (response: any) => {
    //     if (response.status == 'SUCCESS') {
    //       this.departmentList = response.data.shops;
    //       this.setShopInfo(this.departmentList[0].id);
    //       console.log(this.departmentList[0].id);
    //       const newDept = this.departmentList.filter((dept: DepartmentModel) => !this.shopInfo.departments.some(sd => sd.id === dept.id));

    //       this.shopInfo.departments.push(...this.departmentList);
    //     }
    //   });
    // console.log(this.id);
    // this.AreaService.getAreaList(requestData).subscribe(
    //   (response: any) => {
    //     if (response.status == 'SUCCESS') {
    //       this.areaList = response.data.areas;
    //     }
    //   });
    // this.shopService.getShopTypeList().subscribe(
    //   (response: any) => {
    //     if (response.status == 'SUCCESS') {
    //       this.shoptype = response.data.shop_types;
    //     }
    //   });
    // this.shopService.getShopList(requestData).subscribe(
    //   (response: any) => {
    //     if (response.status == 'SUCCESS') {
    //       this.shopList = response.data.shops;
    //       this.mainshop_list = this.shopList.filter((m: any) => m.shop_type === 'SUSHI');
    //       this.sushishop_list = this.shopList.filter((s: any) => s.shop_type === 'SUSHI');
    //       this.bento_shop_list = this.shopList.filter((b: any) => b.shop_type === 'BENTO');
    //     }
    //   });






  }

  setMenuData(menuData: any): void {
    let menuInfoData = menuData.menu;
    this.menuInfo.id = menuInfoData.id;
    this.menuInfo.code = menuInfoData.code;
    this.menuInfo.name = menuInfoData.name;
    this.menuInfo.description = menuInfoData.description;
    this.menuInfo.is_default_menu = menuInfoData.is_default_menu;
    this.menuInfo.enable_h1_button = menuInfoData.enable_h1_button;
    this.menuInfo.enable_h2_button = menuInfoData.enable_h2_button;
    this.menuInfo.enable_h3_button = menuInfoData.enable_h3_button;
    this.menuInfo.color = menuInfoData.color;
    this.menuInfo.is_active = menuInfoData.is_active;
    this.menuInfo.departments = menuData.department;
  }

  DptChange(event: any, dpt: any) {
    this.selectedDpt[dpt.id] = event.checked;

    let dptExist = this.menuInfo.departments.find(item => item.department_id === dpt.id);
    if (this.selectedDpt[dpt.id] == true) {

      if (!dptExist) {
        let dptData = {
          menu_id: this.menuInfo.id,
          department_id: dpt.id
        }
        this.menuInfo.departments.push(dptData);
      }
    }
    else {
      if (dptExist) {
        const index = this.menuInfo.departments.findIndex(dept => dept.department_id === dpt.id);

        if (index !== -1) {
          // Remove the object from the array
          this.menuInfo.departments.splice(index, 1);
        }
      }
    }
    console.log(this.menuInfo.departments);
  }

  onRowSelected(departmentInfo: DepartmentModel): void {
    this.selectedItem = departmentInfo;
    this.showSummaryPanel = true;
  }
  /**
   * If tas is is null set an empty shop info
   * if shop id is not null call api to rtrive the info
   * @param shopId 
   */
  getDeptInfo(): void {

    this.isLoading = true;
    const departmentRequestData = { "search": { "filters": { "scope": { "limit": 10, "offset": 0 } }, "sort": [{ "column": "name", "order": "asc" }] } }
    let response = this.DepartmentService.getDepartmentList(departmentRequestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.departments = response.data.department;
        this.setDataSource(response.data.department);
        if (this.menuData.department != null) {
          this.departments.forEach((item: any) => {
            // Check if the item id is present in comparisonArray
            const match = this.menuData.department.find((dpt: any) => dpt.department_id === item.id);
            this.selectedDpt[item.id] = match ? true : false;
          });
        }
        this.pagingData.length = response.data.total_count;
        if (this.pagingData.length == 0) {
          let snackBarData: SnackBarData = {
            message: 'No data found. Please remove or change the filter if any.',
            title: 'No Data!!!',
            type: 'warn'
          }
          this.snackBarService.openSnackBar(snackBarData);
          this.selectedItem = undefined;
        } else {
          this.onRowSelected(this.departmentList[0])
        }
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.menuInfo.id == 0) ? "New Shop" : "Editing : " + this.menuInfo.name;
    return title;
  }
  // Access the department form array
  // get departmentControls() {
  //   console.log(this.editForm.get('departments'));
  //   return this.editForm.get('departments') as FormArray;
  // }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {
    if (this.editForm.valid) {
      this.editForm.markAllAsTouched();
      Object.assign(this.menuInfo, this.editForm.value);
      console.log(this.menuInfo)
      this.menuService.update(this.menuInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.isFormDirty = false;
            if (this.menuInfo.hasOwnProperty("id")) {
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Saved',
                message: 'The record for ' + this.menuInfo.name + " has been updated succesfully."
              }
            this.snackBarService.openSnackBar(snckBarData);
            }else{
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Saved',
                message: 'The record for ' + this.menuInfo.name + " has been added succesfully."
              }
            this.snackBarService.openSnackBar(snckBarData);
            }
            this.router.navigate(['/menu']);

          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    } else {
      this.editForm.markAllAsTouched();
    }
  }

  onShopTypeChange() {
    let shopType = this.selected_shop_type;
    if (this.selected_shop_type === 'SUSHI') {
      this.is_display_bento_shop = false;
      this.is_display_dt_shops = false;
      this.isDisabled = true;
    } else if (this.selected_shop_type === 'OO') {
      this.is_display_bento_shop = false;
      this.is_display_dt_shops = false;
      this.isDisabled = false;
    } else if (this.selected_shop_type === 'BENTO') {
      this.is_display_bento_shop = true;
      this.is_display_dt_shops = false;
      this.isDisabled = true;
    } else if (this.selected_shop_type === 'DT') {
      this.is_display_bento_shop = false;
      this.is_display_dt_shops = true;
      this.isDisabled = false;
    }

  }
}
