<div class="product-option-bar-root-container" [style.width]="show ? 'var(--left-side-optionbar-width)' : '25px'">
    <div class="menu-list-container">
        <!-- Masters List -->
        <div class="section-container">
            <div class="content item-content">
                <mat-list-item class="mat-list-item" routerLink="/uom" [routerLinkActive]="['is-active']" (click)="resetState()"  [matTooltip]="!show ? 'Category' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/uom.svg">
                        <span class="master-title" style="word-wrap: break-word;">UOM
                        </span>
                    </div>
                </mat-list-item>
                <!-- <mat-list-item class="mat-list-item" routerLink="/stock-category" [routerLinkActive]="['is-active']" [matTooltip]="!show ? 'Employee' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip" >
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/stock-category.svg">
                        <span class="master-title">Stock  Category</span>
                    </div>
                </mat-list-item> -->
                <mat-list-item class="mat-list-item" routerLink="/item-category" [routerLinkActive]="['is-active']" [matTooltip]="!show ? 'Payroll Group' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/item-category.svg">
                        <span class="master-title"> Category
                        </span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/menu" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Shop Payroll' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/menu.svg">
                        <span class="master-title">Menu</span>
                    </div>
                </mat-list-item>
                <!-- <mat-list-item class="mat-list-item" routerLink="/stocks" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Staff Payroll' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/stock-item.svg">
                        <span class="master-title"> Stock Items
                        </span>
                    </div>
                </mat-list-item> -->
                <mat-list-item class="mat-list-item" routerLink="/sale-items" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Attendance Data' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/items.svg">
                        <span class="master-title">Items
                        </span>
                    </div>
                </mat-list-item>

                <mat-list-item class="mat-list-item" routerLink="/choice" [routerLinkActive]="['is-active']" (click)="resetState()" [matTooltip]="!show ? 'Attendance Data' : ''"  matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/combo.svg">
                        <span class="master-title">Choice
                        </span>
                    </div>
                </mat-list-item>
                

         
           
         
         
            </div>
        </div>
    </div>
</div>
