
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChoiceModel } from '../model/choice.model';
import { ChoiceService } from '../choice.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { MatTableDataSource } from '@angular/material/table';
import { SaleItemDialogComponent } from '../sale-item-dialog/sale-item-dialog.component';
@Component({
  selector: 'app-choice-edit',
  templateUrl: './choice-edit.component.html',
  styleUrl: './choice-edit.component.scss'
})
export class ChoiceEditComponent {
 title: string = "New Choice";
   editForm: FormGroup;
   choiceInfo: ChoiceModel = {sale_items:[]};
   isFormDirty: boolean = false;
   ratingList : ChoiceModel[]= [];
    // choiceList: ChoiceModel[]=[];
    saleitemList:ChoiceModel[]=[];
  //  choiceList: any[] = []; 
   dataSource = new MatTableDataSource(this.choiceInfo.sale_items);

  //  displayedColumns = ['code', 'id'];
  displayedColumns= ['SI.NO','code','name','delete']; 

   
   constructor(private router: Router,
     private builder: FormBuilder,
     private route: ActivatedRoute,
     private ChoiceService: ChoiceService,
     private snackBarService: SnackBarService,
     public dialog: MatDialog,) {
     let choiceId = this.route.snapshot.paramMap.get('id');
     console.log(choiceId)
     this.editForm = this.builder.group({
       code: this.builder.control(this.choiceInfo.code, [Validators.required, Validators.maxLength(10)]),
       name: this.builder.control(this.choiceInfo.name, [Validators.required, Validators.maxLength(50)]),
       description: this.builder.control(this.choiceInfo.description),
       is_global: this.builder.control(this.choiceInfo.is_global),
       
     });
     
     this.editForm.valueChanges.subscribe(() => {
       this.isFormDirty = true;
     });
 
     if (choiceId !== null && parseInt(choiceId) !== 0) {
       this.setChoiceInfo(choiceId);
       this.setSaleItemInfo(choiceId);
       
       
     }
     this.setSalesItemInfo(choiceId);

     console.log("id",this.choiceInfo.id)
   }
   ngOnInit(): void {
   //  this.loadRating();    
    }
 
   /**
    * Dirtyness check
    * @returns 
    */
   isDirty():boolean{
     return this.isFormDirty;
   }
 
   /**
    * checks the form is edited
    */
   navBack(): void {
    
       this.router.navigate(['/choice']);
     
   }
 
   /**
    * If tas is is null set an empty choice info
    * if choice id is not null call api to rtrive the info
    * @param choiceId 
    */
   setChoiceInfo(choiceId: any): void {
 
     let response = this.ChoiceService.getChoiceById(choiceId).subscribe({
       next: (response) => {
         if (response.status == 'SUCCESS') {
           this.choiceInfo = {...response.data.choices};
           this.editForm.patchValue(this.choiceInfo);
           this.title = this.getTitle();
           this.isFormDirty = false;
         } else {
           let snackBarData: SnackBarData = {
             message: response.message,
             title: 'Failed!!!',
             type: 'error'
           }
           this.snackBarService.openSnackBar(snackBarData);
         }
       },
       error: (error) => {
         let snackBarData: SnackBarData = {
           message: 'Failed to get data from server. Connection to the server failed.',
           title: 'Failed!!!',
           type: 'error'
         }
         this.snackBarService.openSnackBar(snackBarData);
       }
     });
   }

    // setDataSource(choiceList:ChoiceModel[]){
    //    this.dataSource=new MatTableDataSource(this.choiceInfo.sale_items);
    //   //  this.dataSource.sort=this.sort;
    //  }
   setSaleItemInfo(choiceId: any): void {
 
    let response = this.ChoiceService.getSaleItemById(choiceId).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          // this.setDataSource(response.data.saleitmes);
          // this.choiceList=response.data.saleitmes;
          this.choiceInfo.sale_items=response.data.saleitmes;
          this.dataSource.data = this.choiceInfo.sale_items;
          // this.choiceInfo = response.data.choices;
          this.editForm.patchValue(this.choiceInfo);
          // this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }
 
   /**
    * 
    * @returns  the title for the dialog
    * sine the same dialog is used for edit and new, dynamically decide the title
    
   
 
  
      * On save
      * if the details are valid call the api to update the changes.
      */
   getTitle(): string {
    let title = (this.choiceInfo.id == 0) ? "New choice" : "Editing : " + this.choiceInfo.name;
    return title;
  }
   save(): void {
 
     if (this.editForm.valid) {
       this.editForm.markAllAsTouched();
       Object.assign(this.choiceInfo, this.editForm.value);
       this.ChoiceService.update(this.choiceInfo).subscribe({
         next: (response) => {
           if (response.status == 'SUCCESS') {
             // handle success
             this.isFormDirty = false;
             let snckBarData: SnackBarData = {
               type: 'success',
               title: 'Saved',
               message: 'The record for ' + this.choiceInfo.name + " has been updated succesfully."
             }
             this.snackBarService.openSnackBar(snckBarData);
             this.router.navigate(['/choice']);
           } else {
             let snackBarData: SnackBarData = {
               message: response.message,
               title: 'Failed!!!',
               type: 'error'
             }
             this.snackBarService.openSnackBar(snackBarData);
             this.router.navigate(['/']);
 
           }
         },
         error: (error) => {
           // handle error
           let snackBarData: SnackBarData = {
             message: 'Failed to update. Connection to the server failed',
             title: 'Failed!!!',
             type: 'error'
           }
           this.snackBarService.openSnackBar(snackBarData);
         }
       });
 
     }else{
       this.editForm.markAllAsTouched();
     }
   }

   setSalesItemInfo(choiceId: any): void {
 
    let response = this.ChoiceService.getSalesItemById(choiceId).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          // this.setDataSource(response.data.saleitmes);
          this.saleitemList=response.data.saleitmes;
        
          console.log("data",this.saleitemList)
          // this.choiceInfo = response.data.choices;
          this.editForm.patchValue(this.choiceInfo);
          // this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  openDialog(): void {
    const choiceId = this.route.snapshot.paramMap.get('id');
    this.dialog.open(SaleItemDialogComponent, {
      width: '30%',
      height: '80%',  
      data: { saleitemList: this.saleitemList , choiceId: choiceId } // Pass saleitemList to the dialog
    });
  }


  openChoiceBrowseDialog(): void {
      if (this.choiceInfo.sale_items !== null && this.choiceInfo.sale_items !== undefined) {
        this.saleitemList = this.saleitemList.filter((asi: any) =>
          !this.choiceInfo.sale_items?.some((csi: any) => csi.id === asi.id)
        );
      }
      const editorConfig = {
        // title: "Rewards",
        items: this.saleitemList,
        // selectedItemId: this.saleItemInfo.id,
      };
  
      const dialogRef = this.dialog.open(SaleItemDialogComponent, {
        width: "30%",
        height: "80%", // Adjust the diamensions as needed
        data: editorConfig,
      });
  
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          const selectedItems = result.selectedItems; // Store selected items in the component property
          if (this.choiceInfo !== undefined && this.choiceInfo.sale_items !== undefined ) {
            selectedItems.forEach((ssi: any) => {
              if (!this.choiceInfo.sale_items?.some((csi: any) => csi.id === ssi.id)) {
                this.choiceInfo.sale_items?.push(ssi);
              }
            });
          }else{
            this.choiceInfo.sale_items=selectedItems;
          }
          this.dataSource.data =this.choiceInfo.sale_items;
        }
      });
    }

    deleteFromSelectedChoice(saleItem: any) {
      // console.log(this.choiceList)
      // this.saleitemList.forEach((item1: any) => {
      //   const index = this.choiceList.findIndex((selectedChoice: any) => selectedChoice.choice_id === choiceId);
      //   if (index !== -1) {
      //     // Remove item from array2 and add it to array1
      //     const removedItem = this.choiceList.splice(index, 1)[0];
      //     this.choiceList = [...this.choiceList]
      //     this.saleitemList.push(removedItem);
      //   }
      // });
      // this.selectedChoiceList = this.selectedChoiceList.filter((choice:any) => choice.choice_id !== choiceId);
      saleItem.is_deleted=1;
    }

    
}
