import { Injectable } from "@angular/core";
import { TableLocationModel } from "./model/table.model";
import { TableModel } from "./table-edit/model/table.model";
import { RequestData } from "src/app/common/models/request-data.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class TableLocationService {
  constructor(private http: HttpClient) {}

  getTableList(requestData: RequestData): Observable<any> {
    return this.http.post(environment.baseUrl + "/table/get", requestData);
  }
  getTableimage(requestData: RequestData): Observable<any> {
    return this.http.post(
      environment.baseUrl + "/table/get-image",
      requestData
    );
  }

  getTableById(id: number): Observable<any> {
    return this.http.post(environment.baseUrl + "/table/get/" + id, "");
  }
  getTableDetailsById(id: number): Observable<any> {
    return this.http.post(environment.baseUrl + "/table/get-table/" + id, "");
  }

  /**
   *
   * @param tableInfo
   * Calls the update API to insert/update the record
   */
  update(tableInfo: TableLocationModel): Observable<any> {
    if (tableInfo.hasOwnProperty("id")) {
      return this.http.post(environment.baseUrl + "/table/update", tableInfo);
    } else {
      return this.http.post(environment.baseUrl + "/table/add", tableInfo);
    }
  }
  saveTableDetails(tableInfo: TableModel[]): Observable<any> {
    return this.http.post(
      environment.baseUrl + "/table/update-Table",
      tableInfo
    );
  }

  /**
   *
   * @param tableInfo
   * Calls the API to delete the record
   */
  delete(tableInfo: TableLocationModel): Observable<any> {
    return this.http.post(
      environment.baseUrl + "/table/delete/" + tableInfo.id,
      tableInfo
    );
  }

  /**
   *
   * @param tableInfo
   * Calls the API to delete the record
   */
  tableDelete(tableInfo: TableModel): Observable<any> {
    return this.http.post(
      environment.baseUrl + "/table/delete-table/" + tableInfo.id,
      tableInfo
    );
  }

  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http
      .post(environment.baseUrl + "/hq/area/export-region-list", requestData, {
        headers,
        responseType: "blob",
      })
      .pipe
      //catchError(this.handleError)
      ();
  }
}
