import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { TableModel } from "../model/table.model";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrl: "./table.component.scss",
})
export class TableComponent {
  @Input() table!: TableModel;
  @Output() onSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() selected!: boolean;
  imageListData: any[] = [];
  tableImage: any;

  constructor() {
    this.imageListData = JSON.parse(
      sessionStorage.getItem("imageList") || "[]"
    );
  }
  /**
   * to get the details of table image
   * @returns
   */
  getImage() {
    return this.imageListData.find(
      (imageData) => imageData.id === this.table.layout_image
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["table"]) {
      this.tableImage = this.getImage();
    }
  }

  /**
   * to get the data of the selected-table
   * @param event
   */
  onMouseDown(event: any): void {
    this.onSelected.emit({
      eventType: "mousedown",
      mouseEvent: event,
      table: this.table,
    });
  }
}
