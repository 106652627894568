
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KitchenModel } from '../model/kitchen.model';
import { KitchenService } from '../kitchen.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
@Component({
  selector: 'app-kitchen-edit',
  templateUrl: './kitchen-edit.component.html',
  styleUrl: './kitchen-edit.component.scss'
})
export class KitchenEditComponent {
title: string = "New Kitchen";
  editForm: FormGroup;
  kitchenInfo: KitchenModel = {};
  isFormDirty: boolean = false;
  ratingList : KitchenModel[]= [];
  
  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private KitchenService: KitchenService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,) {

    let kitchenId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.kitchenInfo.code, [Validators.required, Validators.maxLength(10)]),
      name: this.builder.control(this.kitchenInfo.name, [Validators.required, Validators.maxLength(50)]),
      description: this.builder.control(this.kitchenInfo.description)
      
    });
    
    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (kitchenId !== null && parseInt(kitchenId) !== 0) {
      this.setKitchenInfo(kitchenId);
    }
  }
  ngOnInit(): void {
  //  this.loadRating();    
   }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty():boolean{
    return this.isFormDirty;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {
   
      this.router.navigate(['/kitchen']);
    
  }

  /**
   * If tas is is null set an empty kitchen info
   * if kitchen id is not null call api to rtrive the info
   * @param kitechenId 
   */
  setKitchenInfo(kitechenId: any): void {

    let response = this.KitchenService.getKitchenById(kitechenId).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.kitchenInfo = response.data.kitchens;
          this.editForm.patchValue(this.kitchenInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.kitchenInfo.id == 0) ? "New Kitchen" : "Editing : " + this.kitchenInfo.name;
    return title;
  }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {

    if (this.editForm.valid) {
      this.editForm.markAllAsTouched();
      Object.assign(this.kitchenInfo, this.editForm.value);
      this.KitchenService.update(this.kitchenInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.kitchenInfo.name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
            this.router.navigate(['/kitchen']);

          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }else{
      this.editForm.markAllAsTouched();
    }
  }
}
