import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SaleItemService } from '../../sale-item.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-group-items-list',
  templateUrl: './group-items-list.component.html',
  styleUrl: './group-items-list.component.scss'
})
export class GroupItemsListComponent {
  saleItemList: any=[];
  selectedItem:any;
  displayedColumns = ['no', 'code', 'name'];
  pagingData = { length: 0, pageSize: 10, pageIndex: 0 };
  dataSource = new MatTableDataSource(this.saleItemList);
 

  constructor(
    public dialogRef: MatDialogRef<GroupItemsListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private saleItemService : SaleItemService,
    private snackBarService : SnackBarService,
  ) 
  {

  }

  ngOnInit(): void {
    if(this.data.groupId !== null && this.data.groupId !== undefined){
      this.loadMasterData(this.data.groupId);
    }
  }
/**
 * Load items according to group ID
 * @param groupId 
 */
  loadMasterData(groupId:any): void {
    this.saleItemService.getSaleItemById(groupId).subscribe(
      (response: any) => {
        if (response.status == 'SUCCESS') {
          // this.saleItemList = response.data.item_classes;
          this.setDataSource(response.data.item_classes);
          // console.log(response.data)
          // this.setMenuData(response.data);
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });
  }

  setDataSource(saleItemList:any) {
    this.dataSource = new MatTableDataSource(saleItemList);

  }

  @ViewChild(MatSort) sort!: MatSort;
  actionClose(): void {
    this.dialogRef.close();
  }
}
