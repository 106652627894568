import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { RequestData } from 'src/app/common/models/request-data.model';
import { SelectionModel } from '@angular/cdk/collections';
import { ChoiceService } from '../../../choice/choice.service';

@Component({
  selector: 'app-choice-list',
  templateUrl: './choice-list.component.html',
  styleUrl: './choice-list.component.scss'
})
export class ChoiceListComponent {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['select', 'code', 'name'];
  selection: SelectionModel<any>;
  browseBy: any;
  showSelected: boolean = false;
  selectedItems: any;
  // isLoading: boolean;
  choiceList: any;

  constructor(
    private builder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ChoiceListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // private luckyDrawService: CompanyService,// Inject your RewardService
    private snackBarService: SnackBarService,
    private choiceService: ChoiceService
  ) {
    this.dataSource = new MatTableDataSource<any>(data.items);
    // var selectedItem = (data.selectedItems!==null)?data.items.filter((item:any) =>  data.selectedItems.some((si:any)=>si.crm_reward_id===item.id)):[];
    this.selection = new SelectionModel<any>(true, data.selectedItems ?? []);
    this.browseBy = data.browseBy;
  }

  ngOnInit(): void {
    // this.loadChoiceList();
  }

  // loadChoiceList(): void {
  //   this.isLoading = true;
  //   let requestData = {"search":{"filters":{"scope":{"limit":10,"offset":0}},"sort":[]}};
  //   let response = this.choiceService.getChoiceList(requestData).subscribe(response => {
  //     if (response.status == 'SUCCESS') {
  //       this.choiceList = response.data.kitchens;
  //     } else {
  //       let snackBarData: SnackBarData = {
  //         message: response.message,
  //         title: 'Failed!!!',
  //         type: 'error'
  //       }
  //       this.snackBarService.openSnackBar(snackBarData);
  //     }
  //     this.isLoading = false;
  //   });
  // }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  //  isRowSelected(row: any): boolean {
  //   return this.selection.isSelected(row);
  // }
  // showSelectedItems(): void {
  //   if (this.showSelected) {
  //     // If checkbox is checked, update displayed data based on selected items
  //     this.dataSource.data = this.dataSource.data.filter(item => this.isRowSelected(item));
  //   } else {
  //     this.dataSource = new MatTableDataSource<any>(this.data.items);
  // }
  // }

  toggleAllRows(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  save(): void {
    this.selectedItems = this.selection.selected.map(
      (choice: any) => {
        // let rw = this.data.selectedItems.find((item: any) => item.crm_reward_id === choice.id)
        // if (rw) {
          return {
            choice_id: choice.id,
            code:choice.code,
            free_items: 0,
            max_items:-1,
            name:choice.name,
            sale_item_id:this.data.selectedItemId
          };
        // }
      });
    // if(this.data.selectedItems.length==0){
    //   this.selectedItems = this.selection.selected.map(
    //     (reward: any) => {
    //         return {
    //           crm_reward_id: reward.id,
    //           count:0 ,
    //         };
    //       }
    //   );
    // }
    // else{
    //   this.selectedItems = this.selection.selected.map(
    //     (reward: any) => {
    //       let rw=this.data.selectedItems.find((item:any) =>item.crm_reward_id===reward.id)
    //       if(rw){
    //         return {
    //           crm_reward_id: reward.id,
    //           count:rw.count ,
    //         };
    //       }else{
    //         return {
    //           crm_reward_id: reward.id,
    //           count:0 ,
    //         };
    //       }
    //     }
    //   );
    // // }
      this.dialogRef.close({ selectedItems: this.selectedItems });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
