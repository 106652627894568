<div class="edit-page-root-container choice-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
            </div>
        </div>
        <div class="page-details">
            <form class="edit-form" [formGroup]="editForm">
                <div class="detail-section">
                    <div class="content-container">
                        <div class="input-group">
                            <div class="input-box-left"> 
                                <mat-form-field class="code">
                                <mat-label>Code</mat-label>
                                <input matInput formControlName="code" maxlength="50"
                                    [readonly]="choiceInfo.id!==undefined && choiceInfo.id!==0">
                                <mat-error *ngIf="editForm.controls['code'].hasError('required')">choice code should be
                                    entered</mat-error>
                                <mat-error *ngIf="editForm.controls['code'].hasError('maxlength')">Maximum length is
                                    10.</mat-error>
                            </mat-form-field>
                            </div>

                            <div class="input-box-right">  <mat-form-field class="name">
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="name">
                                <mat-error *ngIf="editForm.controls['name'].hasError('required')">choice name should be
                                    entered</mat-error>
                                <mat-error *ngIf="editForm.controls['name'].hasError('maxlength')">Maximum length is
                                    50.</mat-error>
                            </mat-form-field></div>

                        </div>
                        <mat-form-field class="description">
                            <mat-label>Description</mat-label>
                            <textarea matInput formControlName="description"></textarea>
                            <mat-error *ngIf="editForm.controls['description'].hasError('maxlength')">Maximum length is
                                200.</mat-error>
                        </mat-form-field>
                    </div>

                    <mat-checkbox formControlName="is_global" >
                        Global
                       </mat-checkbox>
                       <div class="tab-title" style="display: flex; justify-content: space-between; margin-top: 20px; font-size: 15px; font-weight: bold;">
                        <p>Choice Contents</p>
                        <button mat-flat-button class="action-btn btn-save" style="background-color: green; color: white;" (click)="openChoiceBrowseDialog()">Add</button>
                      </div> 
                    
                     
                       <table mat-table *ngIf="choiceInfo.sale_items" [dataSource]="dataSource"  matSort>

                        <!-- ID Column -->
                        <ng-container matColumnDef="SI.NO">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>SI.NO</th>
                          <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
                        </ng-container>
                      
                        <!-- Code Column -->
                        <ng-container matColumnDef="code">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
                          <td mat-cell *matCellDef="let element">{{ element.code }}</td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                        </ng-container>
                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                          
                            <td mat-cell *matCellDef="let element"> 
                                @if(element.is_deleted!==1){
                                    <mat-icon class="close-button"
                                    (click)="deleteFromSelectedChoice(element)" >delete_forever</mat-icon>
                                }

                                @if(element.is_deleted==1){
                                    <mat-icon class="close-button" style="color: red;"
                                    >delete_forever</mat-icon>
                                }
                            </td>
                          </ng-container>
                       
                      
                      
                        <!-- Table Header and Row -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>

                      

                </div>
             
                   
              
             

           

            </form>

           
        </div>
    </div>

    
</div>