import { Injectable } from '@angular/core';
import { RequestData } from '../../../common/models/request-data.model';
import { SaleItemModel } from './model/sale-item.model';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SaleItemService {

  constructor(private http: HttpClient) { }

  
  getList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/salesitems/get', requestData);

  }

  getSaleItemById(groupId:number):Observable<any> {
    return this.http.post(environment.baseUrl+'/salesitems/getSaleItems/'+groupId,'');
  }

  getChoiceList(saleItemId:number):Observable<any>{
    return this.http.post(environment.baseUrl+'/salesitems/getChoiceList/'+saleItemId,'');
  }

  getSaleItemInfoById(itemId:number):Observable<any> {
    return this.http.post(environment.baseUrl+'/salesitems/get/'+itemId,'');
  }
  
  getStocksList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/hq/stocks/get', requestData);

  }
  getkitchenList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/hq/kitchen/get', requestData);

  }
  getItemClassesList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/hq/itemcategory/get', requestData);

  }
  getTaxList(requestData: RequestData): Observable<any> {
    return this.http.post(environment.baseUrl + '/hq/taxes/get', requestData);

  }
  update(saleItemInfo: SaleItemModel): Observable<any> { 
    if(saleItemInfo.hasOwnProperty("id")){
      return this.http.post(environment.baseUrl+'/salesitems/update', saleItemInfo);
    } else{
      return this.http.post(environment.baseUrl+'/salesitems/add', saleItemInfo);
    }
  }
  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http.post(environment.baseUrl + '/hq/sale-item/export-items', requestData, { headers, responseType: 'blob' })
      .pipe(
        //catchError(this.handleError)
      );

  }

  /**
   * 
   * @param saleItemInfoInfo 
   * Calls the API to delete the record
   */
      delete(saleItemInfo: SaleItemModel): Observable<any>  {
        return this.http.post(environment.baseUrl+'/salesitems/delete', saleItemInfo);
      }
}
