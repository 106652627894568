
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StationModel } from '../model/station.model';
import { StationService } from '../station.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';

@Component({
  selector: 'app-station-edit',
  templateUrl: './station-edit.component.html',
  styleUrl: './station-edit.component.scss'
})
export class StationEditComponent {
title: string = "New Station";
  editForm: FormGroup;
  stationInfo: StationModel = {};
  isFormDirty: boolean = false;
  ratingList : StationModel[]= [];
  
  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private StationService: StationService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,) {

    let stationId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.stationInfo.code, [Validators.required, Validators.maxLength(10)]),
      name: this.builder.control(this.stationInfo.name, [Validators.required, Validators.maxLength(50)]),
      description: this.builder.control(this.stationInfo.description),
      type: this.builder.control(this.stationInfo.type)
      
    });
    
    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (stationId !== null && parseInt(stationId) !== 0) {
      this.setStationInfo(stationId);
    }
  }
  ngOnInit(): void {
  //  this.loadRating();    
   }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty():boolean{
    return this.isFormDirty;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {
   
      this.router.navigate(['/station']);
    
  }

  /**
   * If tas is is null set an empty station info
   * if station id is not null call api to rtrive the info
   * @param stationId 
   */
  setStationInfo(stationId: any): void {

    let response = this.StationService.getStationById(stationId).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.stationInfo = response.data.stations;
          this.editForm.patchValue(this.stationInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.stationInfo.id == 0) ? "New Station" : "Editing : " + this.stationInfo.name;
    return title;
  }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {

    if (this.editForm.valid) {
      this.editForm.markAllAsTouched();
      Object.assign(this.stationInfo, this.editForm.value);
      this.StationService.update(this.stationInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.stationInfo.name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
            this.router.navigate(['/station']);

          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }else{
      this.editForm.markAllAsTouched();
    }
  }
}
