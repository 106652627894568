import { Injectable } from '@angular/core';
import { RequestData } from 'src/app/common/models/request-data.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MenuInfoModel } from './model/menu-settings.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http: HttpClient) { }

  getMenu(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/menu/get', requestData);

  }

  getMenuById(id:number): Observable<any> {
    return this.http.post(environment.baseUrl+'/menu/get/'+id, '');

  }

  
  
  getShopTypeList() {
    return this.http.get(environment.baseUrl + '/hq/shop/getShopTypeList');
  }

   /**
   * 
   * @param shopInfo 
   * Calls the update API to insert/update the record
   */
   update(shopInfo: MenuInfoModel): Observable<any> { 
    if(shopInfo.hasOwnProperty("id")){
      return this.http.post(environment.baseUrl+'/menu/update', shopInfo);
    } else{
      return this.http.post(environment.baseUrl+'/menu/add', shopInfo);
    }
  }


    /**
   * 
   * @param menuInfo 
   * Calls the API to delete the record
   */
    delete(menuInfo: MenuInfoModel): Observable<any>  {
      return this.http.post(environment.baseUrl+'/menu/delete', menuInfo);
    }

    downloadExcel(requestData: any): Observable<Blob> {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL
  
      return this.http.post(environment.baseUrl + '/hq/shop/export-shop-list', requestData, { headers, responseType: 'blob' })
        .pipe(
          //catchError(this.handleError)
        );
  
    }
}
