<div class="edit-page-root-container sale-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{getTitle()}}
            </div>
            <div class="action-btn-container">
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
            </div>
        </div>

        <!-- <mat-tab-group style="background: transparent;">
            <mat-tab label="Sale items" style="text-align: left;"> -->
        <div class="page-details">

            <form class="edit-form" [formGroup]="editForm">
                <mat-tab-group style="background: #fff;">
                    <mat-tab label="General">
                        <div class="detail-section">
                            <div class="content-container">
                                <div class="input-group">
                                    <div class="input-box-left">
                                        <mat-form-field class="code">
                                            <mat-label>code</mat-label>
                                            <input matInput maxlength="20" formControlName="code" [(ngModel)]="saleItemInfo.code" [readonly]="saleItemInfo.id!==undefined && saleItemInfo.id!==0">
                                        </mat-form-field>
                                        <!-- <mat-form-field class="code">
                                            <mat-label>Stock item code</mat-label>
                                            <input matInput maxlength="20" [value]="selectedStock?.id || ''" formControlName="stock_item_id">
                                        </mat-form-field> -->

                                    </div>
                                    <div class="input-box-right">
                                        <mat-form-field class="name">
                                            <mat-label>Name</mat-label>
                                            <input #input type="text" matInput formControlName="name" [(ngModel)]="saleItemInfo.name">
                                        </mat-form-field>
                                    </div>
                                    <!-- Display the selected code -->


                                </div>
                                <!-- <div>
                                    <mat-form-field style="width:50%;">
                                        <mat-label>Item type</mat-label>
                                            <mat-select formControlName="item_type" [(value)]="selectedValue">

                                            <mat-option value="1">Regular item</mat-option>
                                            <mat-option value="2">Customizable item</mat-option>
                                            <mat-option value="3">Combo item</mat-option>
                                          </mat-select>
                                          
                                    </mat-form-field>


                                </div> -->
                                <div class="input-group">
                                    <mat-form-field class="block-input">
                                        <mat-label>Alternative Name</mat-label>
                                        <input matInput maxlength="50" formControlName="alternative_name" [(ngModel)]="saleItemInfo.alternative_name">
                                    </mat-form-field>
                                    <mat-form-field class="block-input">
                                        <mat-label>Name to print</mat-label>
                                        <input matInput formControlName="name_to_print" [(ngModel)]="saleItemInfo.name_to_print">
                                    </mat-form-field>

                                </div>
                                <div class="input-group">
                                    <mat-form-field class="block-input">
                                        <mat-label>Alt.Name to print</mat-label>
                                        <input matInput formControlName="alternative_name_to_print" [(ngModel)]="saleItemInfo.alternative_name_to_print">
                                    </mat-form-field>
                                    <mat-form-field class="block-input">
                                        <mat-label>Barcode</mat-label>
                                        <input matInput formControlName="barcode" [(ngModel)]="saleItemInfo.barcode">
                                    </mat-form-field>
                                </div>
                                <div class="input-group">
                                    <mat-form-field class="block-input">
                                        <mat-label>Group Item</mat-label>
                                        <mat-select formControlName="group_item_id" [(ngModel)]="saleItemInfo.group_item_id">
                                            @for (group of groupItemList; track group.id)
                                            {
                                            <mat-option [value]="group.id">{{group.name}}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="block-input">
                                        <mat-label>Item class</mat-label>
                                        <mat-select formControlName="sub_class_id" [(ngModel)]="saleItemInfo.sub_class_id">
                                            @for (item of itemCategoryList; track item.id)
                                            {
                                            <mat-option [value]="item.id">{{item.name}}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                            </div>
                        </div>
                        <div class="detail-section" style="background-color: var(--content-page-section-bg-color);
                        padding: 10px 30px;
                        margin-top: 10px;
                        border-radius: 5px;">
                            <div class="content-container">
                                <div class="color-container">
                                    <mat-form-field class="block-input">
                                        <mat-label>Display Order</mat-label>
                                        <input matInput formControlName="display_order" [(ngModel)]="saleItemInfo.display_order" type="number">
                                    </mat-form-field>
                                    <mat-form-field class="color-chooser fg-color">
                                        <mat-label>Foreground color</mat-label>
                                        <input matInput type="color" formControlName="fg_color" [(ngModel)]="saleItemInfo.fg_color">
                                    </mat-form-field>
                                    <mat-form-field class="color-chooser bg-color" formControlName="bg_color" [(ngModel)]="saleItemInfo.bg_color">
                                        <mat-label>Background Color</mat-label>
                                        <input matInput type="color">
                                    </mat-form-field>
                                    <!-- <mat-checkbox (change)="toggleColorInput($event.checked)">Use
                                        Multicolor?</mat-checkbox>
                                    @if(isMultiColorChecked) {
                                    <mat-form-field class="color-chooser bg-color">
                                        <mat-label>Background Color-3</mat-label>
                                        <input matInput type="color" formControlName="bg_color2">
                                    </mat-form-field>
                                    } -->
                                    <!-- @else{
                                    <mat-form-field class="color-chooser bg-color">
                                        <mat-label>Background Color-3</mat-label>
                                        <input matInput type="color" formControlName="bg_color2"
                                            [readonly]="!isSecondColorEditable">
                                    </mat-form-field>
                                    } -->
                                </div>

                            </div>
                        </div>
                        <div class="detail-section">
                            <div class="content-container">
                                <!-- <mat-form-field style="width:50%;">
                                    <mat-label>Tax</mat-label>
                                    <mat-select formControlName="tax_id">
                                        @for(tax of tax_id; track tax.id)
                                        {
                                        <mat-option [value]="tax.id">
                                            {{ tax.name}}
                                        </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field> -->
                                <div class="input-group">
                                    <mat-form-field class="block-input">
                                        <mat-label>Fixed Price</mat-label>
                                        <input matInput formControlName="fixed_price" type="number" [(ngModel)]="saleItemInfo.fixed_price">
                                    </mat-form-field>
                                    <mat-form-field class="block-input">
                                        <mat-label>Tax</mat-label>
                                        <mat-select formControlName="tax_id" [(ngModel)]="saleItemInfo.tax_id">
                                            @for (tax of taxList; track tax.id)
                                            {
                                            <mat-option [value]="tax.id">{{tax.name}}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="input-group">
                                    <mat-form-field class="block-input">
                                        <mat-label>UOM</mat-label>
                                        <mat-select formControlName="uom_id" [(ngModel)]="saleItemInfo.uom_id">
                                            @for (uom of uomList; track uom.id)
                                            {
                                            <mat-option [value]="uom.id">{{uom.name}}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="block-input">
                                        <mat-label>Kitchen</mat-label>
                                        <mat-select formControlName="kitchen_id" [(ngModel)]="saleItemInfo.kitchen_id">
                                            @for (kitchen of kitchenList; track kitchen.id)
                                            {
                                            <mat-option [value]="kitchen.id">{{kitchen.name}}</mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <!-- <mat-checkbox formControlName="is_open"> Open Price</mat-checkbox>
                                <mat-checkbox formControlName="is_require_weighing"> Require Weighing</mat-checkbox>
                                <br />
                                <mat-form-field class="block-input" style="width:20%;">
                                    <mat-label>Cost</mat-label>
                                    <input matInput formControlName="item_cost" type="number">
                                </mat-form-field> -->
                        <!-- <div class="detail-section">
                            <div class="content-container">
                                <div class="sub-title">This item is available for direct sale
                                </div>
                                <mat-checkbox formControlName="is_direct_sale_allowed"> Shop</mat-checkbox>
                                <mat-checkbox formControlName="is_oo_direct_sale_allowed"> Online ordering
                                    system</mat-checkbox>
                            </div>
                        </div> -->
                        <!-- <div class="detail-section">
                            <div class="content-container">
                                <div class="sub-title">Kitchen
                                </div>
                                <mat-form-field style="width:50%;">
                                    <mat-label>Kitchen</mat-label>
                                    <mat-select formControlName="kitchen_id">
                                        @for (kitchen of kitchen_id; track kitchen.id)
                                        {
                                        <mat-option [value]="kitchen.id">
                                            {{ kitchen.name }}
                                        </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>

                            </div>
                        </div> -->
                        <!-- <div class="detail-section">
                            <div class="content-container">
                                <div style="display:flex; gap:5px;">
                                    <mat-checkbox formControlName="is_hot_item_1">Hot Item1</mat-checkbox>
                                    <mat-form-field class="block-input">
                                        <mat-label>Display Order</mat-label>
                                        <input matInput formControlName="display_order_1" type="number">
                                    </mat-form-field>
                                    <mat-checkbox formControlName="is_hot_item_2">Hot Item2</mat-checkbox>
                                    <mat-form-field class="block-input">
                                        <mat-label>Display Order</mat-label>
                                        <input matInput formControlName="display_order_2" type="number">
                                    </mat-form-field>
                                    <mat-checkbox formControlName="is_hot_item_3">Hot Item3</mat-checkbox>
                                    <mat-form-field class="block-input">
                                        <mat-label>Display Order</mat-label>
                                        <input matInput formControlName="display_order_3" type="number">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="detail-section">
                            <div class="content-container">
                                <div class="sub-title">This item belongs to the following group
                                </div>
                                <div class="grouping-section" style="display: flex; align-items: center;">
                                    <mat-form-field style="width:50%; margin-right: 10px;">
                                        <mat-label>Grouping</mat-label>
                                        <mat-select>
                                            <mat-option [value]="1">Group 1</mat-option>
                                            <mat-option [value]="2">Group 2</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <button mat-raised-button color="primary">Add</button>

                                </div>
                            </div>
                        </div> -->
                        <div class="detail-section" style="margin-bottom:30px;">
                            <div class="content-container">
                                <div class="input-group">
                                    <div class="block-input">
                                        <mat-checkbox formControlName="is_hot_item_1" [(ngModel)]="saleItemInfo.is_hot_item_1">Hot Item1</mat-checkbox>
                                        <mat-form-field style="margin-left:40px;">
                                            <mat-label>Display Order</mat-label>
                                            <input matInput formControlName="hot_item_1_display_order" type="number" [(ngModel)]="saleItemInfo.hot_item_1_display_order">
                                        </mat-form-field><br />
                                        <mat-checkbox formControlName="is_hot_item_2" [(ngModel)]="saleItemInfo.is_hot_item_2">Hot Item2</mat-checkbox>
                                        <mat-form-field style="margin-left:40px;">
                                            <mat-label>Display Order</mat-label>
                                            <input matInput formControlName="hot_item_2_display_order" type="number" [(ngModel)]="saleItemInfo.hot_item_2_display_order">
                                        </mat-form-field><br />
                                        <mat-checkbox formControlName="is_hot_item_3" [(ngModel)]="saleItemInfo.is_hot_item_3">Hot Item3</mat-checkbox>
                                        <mat-form-field style="margin-left:40px;">
                                            <mat-label>Display Order</mat-label>
                                            <input matInput formControlName="hot_item_3_display_order" type="number" [(ngModel)]="saleItemInfo.hot_item_3_display_order">
                                        </mat-form-field><br />
                                    </div>
                                    <div class="block-input">
                                        <mat-checkbox formControlName="is_manufactured" [(ngModel)]="saleItemInfo.is_manufactured">Direct Sale</mat-checkbox><br />
                                        <mat-checkbox formControlName="is_require_weighing" [(ngModel)]="saleItemInfo.is_require_weighing">Required Weighting</mat-checkbox><br />
                                        <mat-checkbox formControlName="is_valid" [(ngModel)]="saleItemInfo.is_valid">Is Valid</mat-checkbox><br />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </mat-tab>
                    <!-- @if(selectedValue === '2'){
                    <mat-tab label="Customization"></mat-tab>
                    }@else if(selectedValue === '3'){
                    <mat-tab label="Combo Items"></mat-tab>
                    } -->
                    <mat-tab label="Attribute">
                        <div class="detail-section">
                            <div class="content-container">
                                <div class="input-group">
                                    <table id="attributes">
                                        <thead>
                                          <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Options</th>
                                            <th scope="col">Def.Option</th>
                                            <th scope="col">Multi Select</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td><input class="t-input" matInput formControlName="attrib1_name" type="text" [(ngModel)]="saleItemInfo.attrib1_name"></td>
                                            <td><input class="t-input" matInput formControlName="attrib1_options" type="text" [(ngModel)]="saleItemInfo.attrib1_options"></td>
                                            <td><input  class="t-input" matInput formControlName="attrib1_def_option" type="text" [(ngModel)]="saleItemInfo.attrib1_def_option"></td>
                                            <td><mat-checkbox formControlName="attrib1_allow_multiselect" [(ngModel)]="saleItemInfo.attrib1_allow_multiselect"></mat-checkbox></td>
                                          </tr>
                                          <tr>
                                            <td><input class="t-input" matInput formControlName="attrib2_name" type="text" [(ngModel)]="saleItemInfo.attrib2_name"></td>
                                            <td><input class="t-input" matInput formControlName="attrib2_options" type="text" [(ngModel)]="saleItemInfo.attrib2_options"></td>
                                            <td><input  class="t-input" matInput formControlName="attrib2_def_option" type="text" [(ngModel)]="saleItemInfo.attrib2_def_option"></td>
                                            <td><mat-checkbox formControlName="attrib2_allow_multiselect" [(ngModel)]="saleItemInfo.attrib2_allow_multiselect"></mat-checkbox></td>
                                          </tr>
                                          <tr>
                                            <td><input class="t-input" matInput formControlName="attrib3_name" type="text" [(ngModel)]="saleItemInfo.attrib3_name"></td>
                                            <td><input class="t-input" matInput formControlName="attrib3_options" type="text" [(ngModel)]="saleItemInfo.attrib3_options"></td>
                                            <td><input  class="t-input" matInput formControlName="attrib3_def_option" type="text" [(ngModel)]="saleItemInfo.attrib3_def_option"></td>
                                            <td><mat-checkbox formControlName="attrib3_allow_multiselect" [(ngModel)]="saleItemInfo.attrib3_allow_multiselect"></mat-checkbox></td>
                                          </tr>
                                          <tr>
                                            <td><input class="t-input" matInput formControlName="attrib4_name" type="text" [(ngModel)]="saleItemInfo.attrib4_name"></td>
                                            <td><input class="t-input" matInput formControlName="attrib4_options" type="text" [(ngModel)]="saleItemInfo.attrib4_options"></td>
                                            <td><input  class="t-input" matInput formControlName="attrib4_def_option" type="text" [(ngModel)]="saleItemInfo.attrib4_def_option"></td>
                                            <td><mat-checkbox formControlName="attrib4_allow_multiselect" [(ngModel)]="saleItemInfo.attrib4_allow_multiselect"></mat-checkbox></td>
                                          </tr>
                                          <tr>
                                            <td><input class="t-input" matInput formControlName="attrib5_name" type="text" [(ngModel)]="saleItemInfo.attrib5_name"></td>
                                            <td><input class="t-input" matInput formControlName="attrib5_options" type="text" [(ngModel)]="saleItemInfo.attrib5_options"></td>
                                            <td><input  class="t-input" matInput formControlName="attrib5_def_option" type="text" [(ngModel)]="saleItemInfo.attrib5_def_option"></td>
                                            <td><mat-checkbox formControlName="attrib5_allow_multiselect" [(ngModel)]="saleItemInfo.attrib5_allow_multiselect"></mat-checkbox></td>
                                          </tr>
                                          <tr>
                                            <td><input class="t-input" matInput formControlName="attrib6_name" type="text" [(ngModel)]="saleItemInfo.attrib6_name"></td>
                                            <td><input class="t-input" matInput formControlName="attrib6_options" type="text" [(ngModel)]="saleItemInfo.attrib6_options"></td>
                                            <td><input  class="t-input" matInput formControlName="attrib6_def_option" type="text" [(ngModel)]="saleItemInfo.attrib6_def_option"></td>
                                            <td><mat-checkbox formControlName="attrib6_allow_multiselect" [(ngModel)]="saleItemInfo.attrib6_allow_multiselect"></mat-checkbox></td>
                                          </tr>
                                          <tr>
                                            <td><input class="t-input" matInput formControlName="attrib7_name" type="text" [(ngModel)]="saleItemInfo.attrib7_name"></td>
                                            <td><input class="t-input" matInput formControlName="attrib7_options" type="text" [(ngModel)]="saleItemInfo.attrib7_options"></td>
                                            <td><input  class="t-input" matInput formControlName="attrib7_def_option" type="text" [(ngModel)]="saleItemInfo.attrib7_def_option"></td>
                                            <td><mat-checkbox formControlName="attrib7_allow_multiselect" [(ngModel)]="saleItemInfo.attrib7_allow_multiselect"></mat-checkbox></td>
                                          </tr>
                                          <tr>
                                            <td><input class="t-input" matInput formControlName="attrib8_name" type="text" [(ngModel)]="saleItemInfo.attrib8_name"></td>
                                            <td><input class="t-input" matInput formControlName="attrib8_options" type="text" [(ngModel)]="saleItemInfo.attrib8_options"></td>
                                            <td><input  class="t-input" matInput formControlName="attrib8_def_option" type="text" [(ngModel)]="saleItemInfo.attrib8_def_option"></td>
                                            <td><mat-checkbox formControlName="attrib8_allow_multiselect" [(ngModel)]="saleItemInfo.attrib8_allow_multiselect"></mat-checkbox></td>
                                          </tr>
                                          <tr>
                                            <td><input class="t-input" matInput formControlName="attrib9_name" type="text" [(ngModel)]="saleItemInfo.attrib9_name"></td>
                                            <td><input class="t-input" matInput formControlName="attrib9_options" type="text" [(ngModel)]="saleItemInfo.attrib9_options"></td>
                                            <td><input  class="t-input" matInput formControlName="attrib9_def_option" type="text" [(ngModel)]="saleItemInfo.attrib9_def_option"></td>
                                            <td><mat-checkbox formControlName="attrib9_allow_multiselect" [(ngModel)]="saleItemInfo.attrib9_allow_multiselect"></mat-checkbox></td>
                                          </tr>
                                          <tr>
                                            <td><input class="t-input" matInput formControlName="attrib10_name" type="text" [(ngModel)]="saleItemInfo.attrib10_name"></td>
                                            <td><input class="t-input" matInput formControlName="attrib10_options" type="text" [(ngModel)]="saleItemInfo.attrib10_options"></td>
                                            <td><input  class="t-input" matInput formControlName="attrib10_def_option" type="text" [(ngModel)]="saleItemInfo.attrib10_def_option"></td>
                                            <td><mat-checkbox formControlName="attrib10_allow_multiselect" [(ngModel)]="saleItemInfo.attrib10_allow_multiselect"></mat-checkbox></td>
                                          </tr>
                                          <tr>
                                            <td><input class="t-input" matInput formControlName="attrib11_name" type="text" [(ngModel)]="saleItemInfo.attrib11_name"></td>
                                            <td><input class="t-input" matInput formControlName="attrib11_options" type="text" [(ngModel)]="saleItemInfo.attrib11_options"></td>
                                            <td><input  class="t-input" matInput formControlName="attrib11_def_option" type="text" [(ngModel)]="saleItemInfo.attrib11_def_option"></td>
                                            <td><mat-checkbox formControlName="attrib11_allow_multiselect" [(ngModel)]="saleItemInfo.attrib11_allow_multiselect"></mat-checkbox></td>
                                          </tr>
                                          <tr>
                                            <td><input class="t-input" matInput formControlName="attrib12_name" type="text" [(ngModel)]="saleItemInfo.attrib12_name"></td>
                                            <td><input class="t-input" matInput formControlName="attrib12_options" type="text" [(ngModel)]="saleItemInfo.attrib12_options"></td>
                                            <td><input  class="t-input" matInput formControlName="attrib12_def_option" type="text" [(ngModel)]="saleItemInfo.attrib12_def_option"></td>
                                            <td><mat-checkbox formControlName="attrib12_allow_multiselect" [(ngModel)]="saleItemInfo.attrib12_allow_multiselect"></mat-checkbox></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Choice">
                        <div class="detail-section">
                            <div class="content-container">
                                <div class="input-group" style="display: flex;">
                                    <div class="sub-title">Choice List</div>
                                        <button style="width:150px;margin-left: auto;" mat-raised-button color="primary" (click)="openChoiceBrowseDialog()">
                                        Add</button>
                                </div>
                                <div>
                                    <table id="attributes" style="margin-top: 5px;">
                                        <thead>
                                          <tr>
                                            <th scope="col">SI. NO.</th>
                                            <th scope="col">Code</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Free Items</th>
                                            <th scope="col">Max Items</th>
                                            <th scope="col"></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let choice of selectedChoiceList; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{choice.code}}</td>
                                            <td>{{choice.name}}</td>
                                            <td>{{choice.free_items}}</td>
                                            <td>{{choice.max_items}}</td>
                                            <td style="text-align: center;">
                                                <button mat-icon-button aria-label="Delete" (click)="deleteFromSelectedChoice(choice.choice_id)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </td>
                                          </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </form>

        </div>
        <!-- </mat-tab> 
    @if(selectedValue === '2'){
<mat-tab label="Customization"></mat-tab>
    }@else if(selectedValue === '3'){
        <mat-tab label="Combo Items"></mat-tab>


    }


</mat-tab-group> -->

    </div>
</div>