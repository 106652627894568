<div class="order-list">
    <div class="table-list-contianer" *ngIf="customerInfo">
  
      <!-- <div *ngIf="employeeInfo">
        <h2>Employee Information</h2>
        
        <p><strong>Order ID:</strong> {{ customerInfo.order_id }}</p>
        
      </div> -->
      <div class="page-header">
        <button mat-button class="nav-button" (click)="navBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="title">
         Order Details
        </div>
        <div class="action-btn-container">
           
        </div>
    </div>
      <div class="contaner" style="height: 95%;">
    <div  class="page-content">
      <div class="page-content-r1">
        <div class="block">
            <div class="box-header">
              Order 
            </div>
            <div class="box-content">
              <div class="item-list">
                <div class="item">
                  <div class="item-icon">
                    <!-- <mat-icon class="order-dtl-icon">folder</mat-icon> -->
                    <p>Order #</p>
                    
                  </div>
                  <div class="item-label">
                    : {{ customerInfo.order_no || '-'}}
                  </div>
                </div>
                <div class="item">
                  <div class="item-icon">
                    <!-- <mat-icon class="order-dtl-icon">shop</mat-icon> -->
                    <p>Time</p>
                  </div>
                  <div class="item-label">   : {{(customerInfo.order_time) | date: 'yyyy-MM-dd HH:mm' || '-'}} </div>
                </div>
                <div class="item">
                  <div class="item-icon">
                    <!-- <mat-icon class="order-dtl-icon">date_range</mat-icon> -->
                    <p>Items</p>
                  </div>
                  <div class="item-label">
                     <div class="item-label">   : {{ customerInfo.total_qty  || '-'}}</div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-icon"> 
                    <!-- <mat-icon class="order-dtl-icon">payment</mat-icon>  -->
                    <p>Status</p>
                   </div>
                    <div class="item-label"> 
                      
                      @if(customerInfo.status === 0){
                        <div class="status void">
                        :    Void Paid
                        </div>
                        }
                        @if(customerInfo.status === 1){
                        <div class="status open">
                         :       Open
                        </div>
                        }
                        @if(customerInfo.status === 2){
                        <div class="status open">
                            Processing
                        </div>
                        }
                        @if(customerInfo.status === 3){
                            <div class="status closed">
                          :         Closed
                            </div>
                            }
                            @if(customerInfo.status === 4){
                                <div class="status refunded">
                             :       Refunded
                                </div>
                                }
                                
                                    @if(customerInfo.status === 5){
                                        <div class="status void">
                                 :           Void 
                                        </div>
                                        }
                                    
                                    @if(customerInfo.status === 6){
                                        <div class="status void">
                                 :           Partial Pay
                                        </div>
                                        }
                       </div>
                   
                </div>
                <div class="item">
                  <div class="item-icon"> 
                    <!-- <mat-icon class="order-dtl-icon">local_shipping</mat-icon> -->
                    <p>Cashier</p>
                   </div>
                    <div class="item-label">: {{customerInfo.cashier_name || '-'}} </div>
                </div>
                
              </div>
            </div>
          
        </div>
        <div class="block">
            <div class="box-header">
              Customer 
            </div>
            <div class="box-content">
              <div class="item-list">
                <div class="item">
                  <div class="item-icon">
                    <!-- <mat-icon class="order-dtl-icon">account_circle</mat-icon> -->
                    <p>Name</p>
                  </div>
                  <div class="item-label">
                    <div class="item-label">: {{customerInfo.customer_name || '-'}} </div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-icon">
                    <!-- <mat-icon class="order-dtl-icon">location_city</mat-icon> -->
                     <p>Address</p>
                  </div>
                  <div class="item-label">
                          : {{customerInfo.customer_address || '-'}} 
                  </div>
                </div>
                <div class="item">
                  <div class="item-icon">
                    <!-- <mat-icon class="order-dtl-icon">email</mat-icon> -->
                     <p>Phone</p>
                  </div>
                  <div class="item-label">
                     : {{customerInfo.customer_phone || '-'}}
                  </div>
                </div>
                <div class="item">
                  <div class="item-icon">
                    <!-- <mat-icon class="order-dtl-icon">phone</mat-icon> -->
                     <p>Email</p>
                  </div>
                  <div class="item-label">
                    : {{customerInfo.customer_email || '-'}}
                  </div>
                </div>
              </div>
            </div>
         
        </div>
        <div class="block">
          <div class="box-header">
              Payment 
            </div>
            <div class="box-content">
              <div class="item-list">
                <div class="item">
                  <div class="item-icon">
                    <!-- <mat-icon class="order-dtl-icon">phone</mat-icon> -->
                     <p>Amount</p>
                  </div>
                  
                  <div class="item-label">
                    : {{customerInfo.total_amount | currency}}
                  </div>
                </div>
                <!-- <div class="item">
                  <div class="item-icon">
                     <p>Mode</p>
                  </div>
                    <Span>:</Span>
                  <div class="mode-lable" *ngFor="let item of paymentInfo">
                     {{item.payment_mode}} [{{item.total_paid_amount | number:'1.2-2'}}]
                  </div>
                </div> -->
                <!-- <div class="item" style="display: flex; align-items: center;">
                  <div class="item-icon" style="display: flex; align-items: center;">
                    <p style="margin: 0;">Mode</p>
                  </div>
                  <span style="margin: 0 5px; ">:</span>
                  <div class="mode-label" *ngFor="let item of paymentInfo">
                    {{ item.payment_mode }} [{{ item.total_paid_amount | number:'1.2-2' }}]
                  </div>
                </div> -->
                <div class="item" style="display: flex; align-items: center;">
                 
                  <div class="item-icon mode-div">
                    <p style="margin: 0;">Mode</p>
                  </div>
                  <span class="mode-span">:</span>
                
                  <div class="mode-label" *ngFor="let item of paymentInfo">
                    {{ item.payment_mode }} [{{ item.total_paid_amount | number:'1.2-2' }}]
                  </div>
                </div>
                
                <div class="item" style="padding-top: 12px;">
                  <div class="item-icon">
                    <!-- <mat-icon class="order-dtl-icon">phone</mat-icon> -->
                     <p>Time</p>
                  </div>
                  
                  <div class="item-label">
                    : {{(customerInfo.order_time) | date: 'yyyy-MM-dd HH:mm' }}
                  </div>
                </div>
              </div>
              </div>
          <!-- <div class="timeline">
            <ul>
              <li class="list-item">
                
              </li>
            </ul>
          </div> -->
         
        </div>
      </div>
      
      <!-- <div class="order-remmarks" >
       <div class="remarks" [innerHtml]="getOrderRemarks(detailProvider)"></div>
      </div> -->
      <!-- <div class="order-remmarks" *ngIf="employeeInfo.remarks !==undefined && employeeInfo.remarks !==null && employeeInfo.remarks.length>0">
        <div class="remarks" [innerHtml]="getOrderRemarks(employeeInfo)"></div>
       </div> -->
       <!-- <div
  class="order-remmarks"
  *ngIf="customerInfo?.remarks?.trim()">
  <div class="remarks" [innerHTML]="getOrderRemarks(customerInfo)"></div>
</div> -->

<div class="order-remmarks" *ngIf="customerInfo?.remarks?.trim()" >
  <div class="remarks" style="display: flex; align-items: center;">
    <mat-icon>assignment</mat-icon>
    <span style="margin-left: 10px;" [innerHTML]="getOrderRemarks(customerInfo)"></span>
  </div>
</div>
      <div class="page-content-r2">
        <div class="order-table">
          <div class="invoice-model">
            <table class="table table-bordered invoice-table-custom">
              <thead class="invoice">
                <tr class="head">
                  <td class="text-left">Item</td>
                  <td class="text-left">Item Category</td>
                  <td style="text-align: right">Quantity</td>
                  <td style="text-align: right">Unit Price</td>
                  <td style="text-align: right">Discount</td>
                  <td style="text-align: right">Total</td>
                </tr>
              </thead>
              <tbody class="tbody">
                <ng-container *ngFor="let item of itemInfo">
                  <tr>
                    <td class="text-left">{{item.name}} <br>
                      
                      <!-- <ng-container *ngFor="let i of [].constructor(12); let idx = index; let last = last">
                       
                        <span *ngIf="item['attrib' + (idx + 1) + '_name'] && item['attrib' + (idx + 1) + '_selected_option']" style="color: red;font-size:10px;">
                          {{ item['attrib' + (idx + 1) + '_name'] }}:
                          {{ item['attrib' + (idx + 1) + '_selected_option'] }}
                       
                          <span *ngIf="!last">,</span>
                        </span>
                      </ng-container> -->
                      <ng-container *ngFor="let i of [].constructor(12); let idx = index;">
                        <!-- Check if both attribute name and selected option have values -->
                        <span *ngIf="item['attrib' + (idx + 1) + '_name'] && item['attrib' + (idx + 1) + '_selected_option']" style="color: red; font-size: 10px;">
                          {{ item['attrib' + (idx + 1) + '_name'] }}: {{ item['attrib' + (idx + 1) + '_selected_option'] }}
                          <!-- Add comma only if it's not the last valid attribute -->
                          <ng-container *ngIf="idx < 11 && item['attrib' + (idx + 2) + '_name'] && item['attrib' + (idx + 2) + '_selected_option']">,</ng-container>
                        </span>
                      </ng-container>
                      
                      
                      
                    </td>
                    <td class="text-left">{{item.item_category}}</td>
                    <td style="text-align: right">{{item.qty}}</td>
                    <td style="text-align: right">
                      {{item.unit_price | currency}}
                    </td>
                    <td style="text-align: right">
                       {{item.discount_amount| currency}}
                    </td>
                    <td style="text-align: right"> {{item.item_total | currency}}</td>
                  </tr>
                  <!-- <ng-container *ngIf="item.customization !== null && item.customization !== undefined "> -->
                 
                  <!-- <tr>
                
                        <td colspan=2 class="text-left" style="padding-left: 35px;">
                          <div style="display: flex; align-items: center;">

                          <span style="margin-left: 10px;"> </span>
                          </div>
                          
                        </td>
                   
                    <td style="text-align: right"></td>
                    <td style="text-align: right">
                     
                    </td>
                    <td style="text-align: right">
                  
                      <span ></span>
                    </td>
                    <td style="text-align: right"></td>
                  </tr>
                 -->
             
                  <!-- <tr>
                    <td colspan="2" class="text-left" style="padding-left: 35px;">
                      <div style="display: flex; align-items: center;">
                       
                        <span style="margin-left: 10px;"></span>
                      </div>
                    </td>
                    <td style="text-align: right"></td>
                    <td style="text-align: right"></td>
                    <td style="text-align: right">
                      <span ></span>
                    </td>
                    <td style="text-align: right"></td>
                  </tr> -->
                  
                    <!-- <tr >
                     
                          <td colspan=2 class="text-left" style="padding-left: 60px;">
                            <div style="display: flex; align-items: center;">
                          
                            <span style="margin-left: 10px;"> </span>
                            </div>
                            
                          </td>
                      
                      <td style="text-align: right"></td>
                      <td style="text-align: right">
                     
                      </td>
                      <td style="text-align: right">
                          <span ></span>
                        
                      </td>
                      <td style="text-align: right"></td>
                    </tr> -->
                  
                  
                
                    <ng-container>
                      <tr *ngIf="item.remarks">
                        <td colspan="6">
                          
                          <div style="display: flex; align-items: center;">
                            <mat-icon>assignment</mat-icon>
                           <span style="margin-left: 10px;"> {{ item.remarks }} </span>
                           </div>
                        </td>
                      </tr>
                    </ng-container>
                  <!-- </ng-container> -->
                  <!-- <tr   *ngFor="let item of itemInfo">
                    <td colspan="6">
                      {{item.remarks | currency}}
                    </td>
                  </tr> -->
                </ng-container>
                <tr>
                  <td colspan="5" style="text-align: right">Total Amount</td>
                  <td style="text-align: right">
                   {{totalAmount| currency}}
                  </td>
                </tr>
                <tr>
                  <td colspan="5" style="text-align: right">Discount</td>
                  <td style="text-align: right">
                    
                    {{totalDiscount| currency}}
                  </td>
                </tr>
                <tr>
                  <td colspan="5" style="text-align: right">Net Amount</td>
                  <td style="text-align: right">
                     
                    {{netAmount| currency}}
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  
  </div>