import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "src/app/common/components/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-table-image-dialog",
  templateUrl: "./table-image-dialog.component.html",
  styleUrl: "./table-image-dialog.component.scss",
})
export class TableImageDialogComponent {
  imageListData: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<TableImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {
    this.imageListData = JSON.parse(
      sessionStorage.getItem("imageList") || "[]"
    );
   
  }

 /**
  * to pass the data of selected image 
  * @param image 
  */
  selectImage(image: any): void {
    const dialogData = new ConfirmDialogModel(
      "Confirm Selection",
      "Are you sure, you want to use this image?"
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Close the dialog and pass the selected image back to the parent component
        this.dialogRef.close(image);
      }
    });
  }
}
