<div class="edit-page-root-container uom-edit-root-container">
  <div class="page-content">
    <div class="page-header">
      <button mat-button class="nav-button" (click)="navBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="title">
        {{ title }}
      </div>
      <div class="action-btn-container"></div>
    </div>
    <div class="page-details">
      <form class="edit-form">
        <div class="wrapper">
          <div class="container">
            <div
              *ngFor="let table of tableInfo"
              class="table-box"
              cdkDrag
              [id]="'table-' + table.id"
              (cdkDragEnded)="onDragEnd($event, table)"
              [style.transform]="
                'translate(' + table.x + 'px, ' + table.y + 'px)'
              "
            >
              <app-table
                [table]="table"
                (onSelected)="handleSelect($event)"
                [selected]="table.uid === selectedTable?.uid"
              ></app-table>
            </div>
          </div>
          <div class="side-panel">
            <h3>Table Information</h3>
            <div class="form-group">
              <mat-label>Loc:</mat-label>
              <input
                matInput
                [(ngModel)]="tableLocationInfo.name"
                name="name"
                id="location"
                type="text"
                placeholder=""
                readonly
              />
            </div>

            <div class="form-group row">
              <div class="field">
                <mat-label>Table:</mat-label>
                @if(selectedTable){
                <input
                  matInput
                  [(ngModel)]="selectedTable.code"
                  name="tableCode"
                  type="text"
                  placeholder="Table"
                  #tableInput
                />
                }@else{
                <input
                  matInput
                  name="tableCode"
                  type="text"
                  placeholder="Table"
                  readonly
                />
                }
              </div>
              <div class="field">
                <mat-label>Seats:</mat-label>
                @if(selectedTable){
                <input
                  matInput
                  [(ngModel)]="selectedTable.covers"
                  name="seats"
                  id="seats"
                  type="text"
                  placeholder="Seats"
                />
                } @else{
                <input
                  matInput
                  name="seats"
                  id="seats"
                  type="text"
                  placeholder="Seats"
                  readonly
                />
                }
              </div>
            </div>

            <div class="button-group">
              <div class="row">
                <button mat-flat-button class="btn save" (click)="save()">
                  SAVE
                </button>
                <button
                  mat-flat-button
                  class="btn remove"
                  (click)="removeTable()"
                >
                  Remove
                </button>
              </div>
              <div class="row center">
                <button
                  mat-flat-button
                  class="btn add-new"
                  (click)="addTable()"
                >
                  Add New Table
                </button>
              </div>
            </div>

            <div class="preview">
              <div class="preview-content">
                <p class="preview-table-text">Preview Table</p>
                <img
                  src="{{ 'data:image/png;base64,' + selectedImage.image }}"
                  alt=""
                />
                <div class="row center">
                  <button
                    mat-flat-button
                    class="btn select"
                    (click)="openImageSelectionDialog()"
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
