<app-employee-menu> </app-employee-menu>


<div class="area-component-container page-root-container">
        <div class="page-header">
                <div class="page-title">

                      
                                <img  class="icon" src="assets/images/menu/svg/employees.svg">
                                <span class="master-title">Employee</span>
                </div>
                <div class="page-actions">
                        <button mat-button color="primary" class="btn-add"
                                (click)="addClicked()"><mat-icon>person_add</mat-icon>New</button>
                        <!-- <button mat-button color="accent" class="btn-export" (click)="exportClicked()">
                                <mat-icon>save_alt</mat-icon>Export</button> -->
                </div>
        </div>

        <div class="data-container ">
                <app-loader [show]="isLoading"></app-loader>

                <!-- <app-search-filter #searchFilter [length]="pagingData.length" [pageSize]="pagingData.pageSize"
                        [pageSizeOptions]="[5,10,20,50,100]" [pageIndex]="pagingData.pageIndex" [filter]="filter"
                        (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)"  [hidePageSize]="true">
                </app-search-filter> -->
                <app-search-filter #searchFilter [pagingData]="pagingData" [pageSizeOptions]="[5,10,20,50,100]"
                        [filter]="filter" (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)"
                        [hidePageSize]="true">
                </app-search-filter>
                <!-- <ng-container *ngIf="pagingData.length>0; else nodata"> -->
                <!-- @if(pagingData.length > 0){
                                @if(!isHandset){ -->
                <section tabindex="0" class="table-container">
                        <table matSort mat-table [dataSource]="dataSource">
                                <ng-container matColumnDef="item">
                                        <th mat-header-cell *matHeaderCellDef (click)="sortData($event)"
                                                class="item-header">#</th>
                                        <td mat-cell *matCellDef="let employee; let i = index" class="item-column">{{
                                                (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
                                </ng-container> (matSortChange)="sortData($event)">
                                <ng-container matColumnDef="code">
                                        <th mat-sort-header="employees.code" mat-header-cell style="width: 80px;"
                                                *matHeaderCellDef (click)="sortData($event)">Code</th>
                                        <td mat-cell *matCellDef="let employee" style="width: 80px;">
                                                {{employee.code}}
                                        </td>
                                </ng-container>

                                <ng-container matColumnDef="f_name">
                                        <th mat-sort-header="employees.f_name" mat-header-cell *matHeaderCellDef
                                                (click)="sortData($event)"> Name </th>
                                        <td mat-cell *matCellDef="let employee"> {{employee.f_name}} {{employee.l_name}} 
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="employee_category_id">
                                        <th mat-sort-header="employees.employee_category_id" mat-header-cell *matHeaderCellDef 
                                                (click)="sortData($event)"> Catogery </th>
                                        <td mat-cell *matCellDef="let employee" >
                                                {{employee.category_name}} 
                                                
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="department_id">
                                        <th mat-sort-header="employees.department_id" mat-header-cell *matHeaderCellDef 
                                                (click)="sortData($event)"> Department </th>
                                        <td mat-cell *matCellDef="let employee " > 
                                                {{employee.department_name}}
                                        </td>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                        <th mat-sort-header="employees.status" mat-header-cell *matHeaderCellDef style="width: 150px;"
                                                (click)="sortData($event)" [attr.rowspan]="2"
                                                class="customer-type-header">status</th>
                                        <td mat-cell *matCellDef="let employee" class="customer-type-column" style="width: 150px;">
                                                @if(employee.status === 1){
                                                <div class="status register">
                                                        Active
                                                </div>
                                                }
                                                @if(employee.status === 2){
                                                <div class="status guest">
                                                        Resigned
                                                </div>
                                                }
                                               
                                        </td>
                                </ng-container>
                                <!-- <ng-container matColumnDef="description">
                                        <th mat-sort-header="description" mat-header-cell *matHeaderCellDef
                                                (click)="sortData($event)"> Description </th>
                                        <td mat-cell *matCellDef="let area"> {{area.description}}
                                        </td>
                                </ng-container> -->
                                <ng-container matColumnDef="action" stickyEnd>
                                        <th mat-header-cell *matHeaderCellDef> </th>
                                        <td mat-cell class="row-menu-btn" style="width:50px" *matCellDef="let rowItem">
                                                <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
                                                        (click)="onRowSelected(rowItem);$event.stopPropagation();">
                                                        <mat-icon>more_vert</mat-icon>
                                                </a>

                                                <mat-menu #rowMenu>
                                                        <button mat-menu-item
                                                                (click)="onDelete(rowItem)">Delete</button>
                                                        <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>
                                                </mat-menu>
                                        </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                </tr>
                                <tr mat-row class="table-element-row"
                                        *matRowDef="let employee; columns: displayedColumns;"
                                        (click)="onRowSelected(employee)" (dblclick)="onEdit(employee)"
                                        [ngClass]=" {'row-selected' : employee.id===selectedItem?.id}">
                                </tr>
                        </table>
                </section>

                <!-- </ng-container> -->
                <!-- <ng-template #nodata> -->
                @if(pagingData.length<=0){ <div class="no-data-container">
                        <img src="assets/images/no-data.png">
                        <span class="no-data-info">No data found. Please remove or redefine the filters if
                                any.</span>
        </div>
        <!-- </ng-template> -->
        }
</div>

</div>

