
import { Injectable } from '@angular/core';
import { KitchenModel } from './model/kitchen.model';
import { RequestData } from 'src/app/common/models/request-data.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class KitchenService {

  
    constructor(private http: HttpClient) { }
  
    getKitchenList(requestData:RequestData): Observable<any> {
      return this.http.post(environment.baseUrl+'/kitchen/get', requestData);
  
    }
  
    getKitchenById(id:number): Observable<any> {
      return this.http.post(environment.baseUrl+'/kitchen/get/'+id, '');
  
    }
  
     /**
     * 
     * @param kitchenInfo 
     * Calls the update API to insert/update the record
     */
     update(kitchenInfo: KitchenModel): Observable<any> { 
      if(kitchenInfo.hasOwnProperty("id")){
        return this.http.post(environment.baseUrl+'/kitchen/update', kitchenInfo);
      } else{
        return this.http.post(environment.baseUrl+'/kitchen/add', kitchenInfo);
      }
    }
  
  
      /**
     * 
     * @param kitchenInfo 
     * Calls the API to delete the record
     */
      delete(kitchenInfo: KitchenModel): Observable<any>  {
        return this.http.post(environment.baseUrl+'/kitchen/delete/'+kitchenInfo.id, kitchenInfo);
      }
  
      downloadExcel(requestData: any): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL
    
        return this.http.post(environment.baseUrl + '/hq/area/export-region-list', requestData, { headers, responseType: 'blob' })
          .pipe(
            //catchError(this.handleError)
          );
    
      }
}
