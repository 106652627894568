<app-product-menu> </app-product-menu>

<div class="uom-component-container page-root-container">
    <div class="page-header">
        <div class="page-title">
            <img class="icon" src="assets/images/menu/svg/menu.svg"><span class="title">MENU</span>
        </div>
        <div class="page-actions">
            <button mat-button color="primary" class="btn-add"
                (click)="addClicked()"><mat-icon>person_add</mat-icon>New</button>
            <!-- <button mat-button color="accent" class="btn-export" (click)="exportClicked()"> <mat-icon>save_alt</mat-icon>Export</button> -->
        </div>
    </div>

    <div class="data-container ">
        <app-loader [show]="isLoading"></app-loader>
        <app-search-filter #searchFilter [pagingData]="pagingData" [pageSizeOptions]="[5,10,20,50,100]"
            [filter]="filter" (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
        </app-search-filter>
        @if(pagingData.length>0){
        @if(!isHandset){
        <section tabindex="0" class="table-container">
            <table matSort mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)">
                <ng-container matColumnDef="code">
                    <th mat-sort-header="code" mat-header-cell style="width: 80px;" *matHeaderCellDef>Code</th>
                    <td mat-cell *matCellDef="let menu" style="width: 80px;"> {{menu.code}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-sort-header="name" mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let menu"> {{menu.name}}
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef="description">
                    <th mat-sort-header="description" mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let menu"> {{menu.description}}
                    </td>
                </ng-container> -->
                <ng-container matColumnDef="enable_h1_button">
                    <th mat-sort-header="enable_h1_button" mat-header-cell *matHeaderCellDef> H1 </th>
                    <td mat-cell *matCellDef="let menu">
                        @if (menu.enable_h1_button==1)
                        {
                        <span style='font-size:20px;'>&#10004;</span>
                        }
                    </td>
                </ng-container>
                <ng-container matColumnDef="enable_h2_button">
                    <th mat-sort-header="enable_h2_button" mat-header-cell *matHeaderCellDef> H2 </th>
                    <td mat-cell *matCellDef="let menu">
                        @if (menu.enable_h2_button==1)
                        {
                        <span style='font-size:20px;'>&#10004;</span>
                        }
                    </td>
                </ng-container>
                <ng-container matColumnDef="enable_h3_button">
                    <th mat-sort-header="enable_h3_button" mat-header-cell *matHeaderCellDef> H3 </th>
                    <td mat-cell *matCellDef="let menu">
                        @if (menu.enable_h1_button==1)
                        {
                        <span style='font-size:20px;'>&#10004;</span>
                        }
                    </td>
                </ng-container>
                <ng-container matColumnDef="is_default_menu">
                    <th mat-sort-header="is_default_menu" mat-header-cell *matHeaderCellDef> Default </th>
                    <td mat-cell *matCellDef="let menu">
                        @if (menu.is_default_menu==1)
                        {
                        <span style='font-size:20px;'>&#10004;</span>
                        }
                    </td>
                </ng-container>
               
                <ng-container matColumnDef="action" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell class="row-menu-btn" style="width:50px" *matCellDef="let rowItem">
                        <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
                            (click)="onRowSelected(rowItem);$event.stopPropagation();">
                            <mat-icon>more_vert</mat-icon>
                        </a>
                        <mat-menu #rowMenu>
                            <button mat-menu-item (click)="onDelete(rowItem)">Delete</button>
                            <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>

                        </mat-menu>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                </tr>
                <tr mat-row class="table-element-row" *matRowDef="let uom; columns: displayedColumns;"
                    (click)="onRowSelected(uom)" (dblclick)="onEdit(uom)"
                    [ngClass]=" {'row-selected' : uom.id===selectedItem?.id}">
                </tr>
            </table>
        </section>
        }
        }@else{

        <div class="no-data-container">
            <img src="assets/images/no-data.png">
            <span class="no-data-info">No data found. Please remove or redefine the filters if
                any.</span>
        </div>
        }
    </div>

</div>
<!-- <app-uom-summary [uom]="selectedItem" (onEdit)="onEdit($event)" (onDelete)="onDelete($event)"></app-uom-summary> -->