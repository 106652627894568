import { Component,  Input, } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TableLocationModel } from "../model/table.model";
import { TableLocationService } from "../table-location.service";
import { SnackBarData } from "src/app/common/components/snackbar/model/snackbar.model";
import { SnackBarService } from "src/app/common/components/snackbar/snackbar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TableModel } from "./model/table.model";
import { CdkDragEnd } from "@angular/cdk/drag-drop";
import { TableImageDialogComponent } from "./table-image-dialog/table-image-dialog.component";

@Component({
  selector: "app-table-edit",
  templateUrl: "./table-edit.component.html",
  styleUrl: "./table-edit.component.scss",
})
export class TableEditComponent {
  title: string = "New Table";
  editForm: FormGroup;
  tableInfo: TableModel[] = [];
  isFormDirty: boolean = false;
  selectedTable: any;
  selectedImage: any;
  @Input() Onselected!: boolean;
  imageListData: any;
  tableLocationInfo: TableLocationModel = {};

  constructor(
    private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private tableService: TableLocationService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog
  ) {
    this.imageListData = JSON.parse(
      sessionStorage.getItem("imageList") || "[]"
    );
    this.selectedImage=this.imageListData[0];
    let tableLocId = this.route.snapshot.paramMap.get("id");
    this.editForm = this.builder.group({
      name: this.builder.control("", [Validators.required]),
      seat: this.builder.control("", [Validators.required]),
      tablecode: this.builder.control("", [Validators.required]),
    });

    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (tableLocId !== null && parseInt(tableLocId) !== 0) {
      this.setTableInfo(tableLocId);
      this.setTablelocationInfo(tableLocId);
    }
  }

  ngOnInit(): void {
   
  }

  /**
   * Dirtyness check
   * @returns
   */
  isDirty(): boolean {
    return this.isFormDirty;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {
    this.router.navigate(["/table-location"]);
  }

 /**
  * to get table-info
  * @param tableId 
  * @param resetSelection 
  */
  setTableInfo(tableId: any,resetSelection:boolean=true ): void {
    let response = this.tableService.getTableDetailsById(tableId).subscribe({
      next: (response) => {
        if (response.status == "SUCCESS") {
          this.tableInfo = response.data.table.map((table: any) => ({
            ...table,
            x: table.column_position,
            y: table.row_position,
            uid: this.getUID(),
          }));
          if(resetSelection){
            if (this.tableInfo !== undefined && this.tableInfo.length > 0) {
              this.handleSelect({ table: this.tableInfo[0] });
            }
          }
          else{
            this.selectedTable=this.tableInfo.find(tbl=>tbl.code==this.selectedTable.code);
           
          }

          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message:
            "Failed to get data from server. Connection to the server failed.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }
  /**
   * to get the table-location-info 
   * @param tableId 
   */
  setTablelocationInfo(tableId: any): void {
    let response = this.tableService.getTableById(tableId).subscribe({
      next: (response) => {
        if (response.status == "SUCCESS") {
          this.tableLocationInfo = response.data.table;
          this.editForm.patchValue(this.tableLocationInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message:
            "Failed to get data from server. Connection to the server failed.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }
 
/**
 * to drag the table and update the table position
 * @param event 
 * @param table 
 */
  onDragEnd(event: CdkDragEnd, table: any): void {
    // Update x and y positions
    table.x += event.distance.x;
    table.y += event.distance.y;
    event.source.getRootElement().style.transform = "translate(0, 0)";
  }

/**
 * to get the data of the selected table 
 * @param event 
 */
  handleSelect(event: any): void {
    this.selectedTable = event.table;
    this.selectedImage = this.getImage(this.selectedTable.layout_image);
   if(this.selectedImage==undefined){
    this.selectedImage=this.imageListData[0];
   } 
    if (this.selectedTable) {
      this.editForm.patchValue({
        // code: this.selectedTable.code,
        seat: this.selectedTable.covers,
        tablecode: this.selectedTable.code,
      });
    }
  }

 /**
   * to add and update table-info 
   */
  save(): void {
    const errTable = this.tableInfo.find((table) => table.code?.trim() == "");
    if (errTable !== undefined) {
      this.handleSelect({ table: errTable });

      // Show validation error message
      const snackBarData: SnackBarData = {
        message: "Please enter table code.",
        title: "Validation Error",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);

      return;
    }
    const codeOccurrences = this.tableInfo.reduce((acc, table) => {
      const code = table.code?.trim();
      if (code) {
        acc[code] = (acc[code] || 0) + 1;
      }
      return acc;
    }, {} as Record<string, number>);
  
    const duplicateCode = Object.keys(codeOccurrences).find(
      (code) => codeOccurrences[code] > 1
    );
  
    if (duplicateCode) {
      const snackBarData: SnackBarData = {
        message: `Table code "${duplicateCode}" already exists.`,
        title: "Validation Error",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }
    // Update column_position and row_position with the current x and y values
    this.tableInfo = this.tableInfo.map((table) => ({
      ...table,
      column_position: table.x,
      row_position: table.y,
    }));

    // Call the service to save the updated data
    this.tableService.saveTableDetails(this.tableInfo).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          this.setTableInfo(this.tableLocationInfo.id,false);
          const snackBarData: SnackBarData = {
            message: "Tables saved successfully!",
            title: "Success",
            type: "success",
          };
          this.snackBarService.openSnackBar(snackBarData);
        
        } else {
          const snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: () => {
        const snackBarData: SnackBarData = {
          message: "Failed to save data. Please try again later.",
          title: "Error",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }
/**
 * to get the details of selected image 
 * @param imageId 
 * @returns 
 */
  getImage(imageId: any) {
    return this.imageListData.find(
      (imageData: any) => imageData.id === imageId
    );
  }

  /**
   * to open the image-selection dialog box 
   */
  openImageSelectionDialog(): void {
    const dialogRef = this.dialog.open(TableImageDialogComponent, {
      width: "50%",
      height: "50%",
    });

    dialogRef.afterClosed().subscribe((selectedImage) => {
      if (selectedImage) {
        // Update the selectedImage in the parent component
        this.selectedImage = selectedImage;

        // Update the layout_image of the selected table
        if (this.selectedTable) {
          var index = this.tableInfo.findIndex(
            (table) => table.id == this.selectedTable.id
          );
          this.selectedTable = {
            ...this.selectedTable,
            layout_image: this.selectedImage.id,
          };
          this.tableInfo[index] = this.selectedTable;
        }

      
       
      } else {
     
      }
    });
  }

/**
 * to add a new table 
 */
  addTable(): void {
    // Initialize with empty table code and seat
    const tableCode = this.editForm.get("tablecode")?.value || "";
    const tableseat = this.editForm.get("seat")?.value || "";

    // Create a new table with the provided tableCode and seat
    const newTable: TableModel = {
      id: 0,
      uid: this.getUID(),
      code: "", 
      name: "",
      description: "",
      serving_table_location_id: this.tableLocationInfo.id,
      covers: 4,
      row_position: 0,
      column_position: 0,
      x: 0,
      y: 0,
      layout_image: this.selectedImage.id,
    };

    // Check if the code already exists in the tableInfo array
    const existingTable = this.tableInfo.find(
      (table) => table.code === newTable.code
    );

    // Only add the new table if its code is unique
    if (!existingTable) {
      this.tableInfo.push(newTable);
      this.handleSelect({ table: newTable });

    
    } else {
      
      // Show Snackbar with error message
      const snackBarData: SnackBarData = {
        message: `Table with code "${newTable.code}" already exists.`,
        title: "Duplicate Code",
        type: "error",
      };
      // this.snackBarService.openSnackBar(snackBarData);
    }

  
  }
/**
 * to remove a table 
 */
  removeTable(): void {
    this.tableService.tableDelete(this.selectedTable).subscribe({
      next: (response) => {
        if (response.status == "SUCCESS") {
          // handle success
          // Find the index of the selected table
          var index = this.tableInfo.findIndex(
            (table) => table.uid === this.selectedTable.uid
          );

          // If the table exists, remove it from the tableInfo array
          if (index !== -1) {
            this.tableInfo.splice(index, 1);

            // Optionally, clear the selectedTable after removal
            if (this.tableInfo.length > 0) {
              this.selectedTable =
                index > 0
                  ? this.tableInfo[index - 1]
                  : this.tableInfo[index + 1];
              this.handleSelect({ table: this.selectedTable });
            } else {
              this.selectedTable = null;
            }

            // Display a success message
          }
          let snckBarData: SnackBarData = {
            type: "success",
            title: "Saved",
            message: "Table deleted successfully. ",
          };
          this.snackBarService.openSnackBar(snckBarData);
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to update. Connection to the server failed",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }
  /**
   * 
   * @returns to give unique id
   */
  getUID() {
    return `${Date.now()}-${Math.random().toString(36).slice(2, 8)}`;
  }

 /**
     * 
     * @returns  the title for the dialog
     * sine the same dialog is used for edit and new, dynamically decide the title
     */
  getTitle(): string {
    let title = (this.tableLocationInfo.id == 0) ? "New Table" : "Editing : " + this.tableLocationInfo.name;
    return title;
  }
}
